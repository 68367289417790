"use client"
import { isEmpty } from "@/src/utils";
import { EyeOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Modal, Select, Upload } from "antd";
import Image from "next/image";
import React from "react";
import "../../../../../../styles/submitWebsite/websiteSubmit.scss";
import { ButtonComponent } from "@/src/components/CommonComponents";
import { imageScreenshot } from "@/public/assets";

const { Dragger } = Upload;
const { Option } = Select;

const FinishingUpView = ({
  isStatus,
  websiteError,
  handleFontChange,
  handleImageFileChange,
  handlePreviewImage,
  handleGetWebScapDetails,
  fontFamilyData,
  submitWebImage,
  submitWebsiteData,
  image,
  handleCancelPreviewModel,
  isPreviewImage,
  handleSubmitWebsitePrevStep,
  handleSubmitWebsiteData,
  current,
}) => {
  const props = {
    name: "file",
    showUploadList: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      handleImageFileChange(info.file);
    },
  };
  return (
    <div className={"submit-main"}>
      <div className={`${"submit-form-wrap"} ${"fade-up-animation2 "}`}>
        <div className={`${"submit-form-main"} ${"font-dropdown"}`}>
          <div className={"form-details"}>
            <p className={"details-number"}>08</p>
            <label className={"form-title"}>{"Fonts*"}</label>
          </div>
          <div className={"font-input-main"}>
            <Select
              disabled={isStatus && true}
              mode="multiple"
              value={submitWebsiteData?.fonts}
              placeholder="Select Fonts"
              onChange={(e) => handleFontChange(e)}
              style={{
                width: "100%",
              }}
              variant={false}
              defaultOpen={false}
            >
              {!isEmpty(fontFamilyData) &&
                fontFamilyData?.map((obj) => {
                  return (
                    <Option
                      value={obj?.fontName}
                      key={obj?.fontName}
                      disabled={obj?.isDisabled && !obj?.isSelected}
                    >
                      {obj?.fontName}
                    </Option>
                  );
                })}
            </Select>
            <p className={"font-website-error-message"}>
              {websiteError?.fonts}
            </p>
          </div>
        </div>
      </div>
      <div className={`${"upload-image-main"} ${"fade-up-animation3"}`}>
        <div className={"upload-image-label-main"}>
          <p className={"details-number"}>09</p>
          <label className={"form-title"}>{"Upload Image*"}</label>
        </div>
        <div className={"middle"}>
          <Dragger
            {...props}
            disabled={isStatus && true}
            accept="image/png, image/jpeg  ,image/webp "
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from
              uploading company data or other banned files.
            </p>
          </Dragger>
          <div className={`${image ? "image-hover-main" : "image"}`}>
            {image || submitWebImage ? (
              <Image
                src={
                  image
                    ? URL.createObjectURL(image)
                    : `data:image/jpeg;base64,${submitWebImage}`
                }
                alt=""
                height={270}
                width={270}
                className={"card-image"}
              />
            ) : (
              <div className={"image-icon-main"}>
                <Image
                  src={imageScreenshot}
                  alt=""
                  height={40}
                  width={40}
                  className={"card-icon"}
                />
                <p className={"preview-image_box"}>
                  A preview will be seen here
                </p>
              </div>
            )}
            {image && (
              <div className={"show-icon-main"} onClick={handlePreviewImage}>
                <EyeOutlined className={"eye_icon"} />
              </div>
            )}
          </div>
        </div>

        <div className={"upload-image-buttons-main"}>
          <p className={"image-size-error"}>{websiteError?.image}</p>
          <ButtonComponent
            btnText={"WebScrap"}
            disabled={isStatus && true}
            handleClick={handleGetWebScapDetails}
            classNames={"web-scrap-btn"}
          />
        </div>
      </div>
      <div className={`${"submit-button-main"} ${"fade-up-animation4"}`}>
        {current > 0 && (
          <ButtonComponent
            handleClick={() => handleSubmitWebsitePrevStep()}
            classNames={"preview-btn"}
            btnText={"Previous"}
          />
        )}
        <ButtonComponent
          btnText={isStatus ? <LoadingOutlined /> : "Submit"}
          classNames={"next-btn"}
          handleClick={handleSubmitWebsiteData}
          disabled={isStatus && true}
        />
      </div>
      <Modal
        open={isPreviewImage}
        onCancel={handleCancelPreviewModel}
        footer={false}
        closeIcon={false}
        className={"font_modal_image"}
        centered
      >
        <div className={"preview-image-main"}>
          <Image
            src={image && URL.createObjectURL(image)}
            alt="image"
            height={400}
            width={400}
            className={"preview-image"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default FinishingUpView;
