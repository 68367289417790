"use client";
import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "../../../../../styles/support/support.scss";
import { BackButtonComponent } from "@/src/components/CommonComponents";
import Link from "next/link";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Managing Uxvortex Email Preferences",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          To adjust your Uxvortex email notifications visit home page &gt;
          Notification Icon &gt;&nbsp;
          <Link href="/notification" className={"getting_start_link"}>
            Email Notifications
          </Link>
          Keep in mind that logging in is required to access this page.
        </p>
      </div>
    ),
    style: panelStyle,
  },
];

const EmailAssistanceView = () => {
  const panelStyle = {
    marginBottom: 24,
    border: "none",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={"1"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: "calc(100vh - 374px)",
        // }}
        className={`${"support_section_page_main"} ${"close"}`}
        items={getItems(panelStyle)}
      />
    </div>
  );
};

export default EmailAssistanceView;
