"use client";
import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { BackButtonComponent } from "@/src/components/CommonComponents";
import "../../../../../styles/support/support.scss";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Understanding Uxvortex Accounts",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          To prevent misuse on Uxvortex, basic accounts have restrictions on
          features reserved for free Designer accounts. While anyone can sign up
          to explore, sharing work on Uxvortex remains private
          <span className={"profile_details_paragraph"}>
            visible only on your profile
          </span>
          until approval as a Designer.
        </p>
        <ul className={"profile_crafting_text_main"}>
          <li className={"profile_crafting_text"}>Recommendations:</li>
          <ol type="1">
            <li>
              <h1 className={"design_account_questions"}>Upload Your Work:</h1>
              <p className={"design_account_answer"}>
                Detailed uploads help our team review your profile efficiently.
              </p>
            </li>
            <li>
              <h1 className={"design_account_questions"}>
                Complete Your Profile:
              </h1>
              <p className={"design_account_answer"}>
                Enhance your profile with an profile photo, name, location, and
                contact number.
              </p>
            </li>
            <li>
              <h1 className={"design_account_questions"}>
                Set up Website Details:
              </h1>
              <p className={"design_account_answer"}>
                Filling up of this details Agency Name, Website URL, Website
                Title, Description, Country, Category and Style will set up your
                account completely.
              </p>
            </li>
          </ol>
          <p>Once your profile is set up, click "Submit".</p>
        </ul>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Clarifications on Applying for a Designer Account:",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          For those still curious about Uxvortex's application process, here are
          answers to common questions:
        </p>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            What does the review team seek? -
          </h1>
          <p className={"design_account_question_answers"}>
            The review team evaluates digital design work you've created,
            spanning animation, branding, illustration, product design,
            typography, and web design.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            What work types are restricted? -
          </h1>
          <p className={"design_account_question_answers"}>
            Uxvortex focuses on digital design, excluding photography,
            copyrighted/trademarked content, and templated or watermarked design
            work.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            What if my work isn't ready to showcase? -
          </h1>
          <p className={"design_account_question_answers"}>
            No worries! Many designers start with earlier pieces, showcasing
            their progression over time. It's a great way to receive feedback
            and illustrate your growth.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            What if I'm not a professional designer? -
          </h1>
          <p className={"design_account_question_answers"}>
            Uxvortex welcomes creators passionate about good design, regardless
            of professional status. Whether you're a side-hustler, student, or
            work in another industry, feel free to share your work and join the
            design community.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Limited Visibility: </h1>
          <p className={"design_account_question_answers"}>
            Your work is exclusively visible to you and isn't accessible from
            other user accounts only when his websites are approved.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Community Interaction: </h1>
          <p className={"design_account_question_answers"}>
            The community is unable to like or save your work, options are
            restricted.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
];
const DesignerAccountView = () => {
  const [isCollapsed, setIsCollapsed] = useState(["1", "2"]);
  const panelStyle = {
    marginBottom: 24,
    border: "none",
    maxWidth: "800px",
    margin: "0 auto",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: isCollapsed?.length <= 0 ? "calc(100vh - 374px)" : "auto",
        // }}
        items={getItems(panelStyle)}
        onChange={(e) => setIsCollapsed(e)}
        className={`${"support_section_page_main"} ${
          isCollapsed?.length >= 1 ? "open" : "close"
        }`}
      />
    </div>
  );
};

export default DesignerAccountView;
