import {
  ImageComponent,
  InputComponent,
} from "@/src/components/CommonComponents";
import { isEmpty } from "@/src/utils";
import React from "react";
import Image from "next/image";
import "../../.././../../../styles/home/home.scss";
import { close, searchIcon } from "../../../../../../public/assets";

const SearchView = ({
  handleRemoveCookies,
  handleSearchValue,
  handleCategorySearchKeyDown,
  searchCookies,
  categorySearch,
  handleFilterChange,
  handleAddSearch,
}) => {
  return (
    <React.Fragment>
      <div className={"search-input-main"}>
        <InputComponent
          value={categorySearch?.searchKey}
          name={"Search"}
          handleChange={(e) => handleFilterChange(e, "Search")}
          handleKeyDown={(e) => handleCategorySearchKeyDown(e, "Search")}
          placeholder="Search Inspiration"
          classNames={"search-input"}
          maxLength={20}
        />
        <ImageComponent
          imageSrc={searchIcon}
          imageAlt="searchIcon"
          imageClassName={"search-icon"}
        />
        {categorySearch?.searchKey && (
          <Image
            src={close}
            alt="close"
            onClick={handleSearchValue}
            className={"delete-icon"}
          />
        )}
      </div>
      {!isEmpty(searchCookies) && (
        <div className={"search-history-main"}>
          {Array.isArray(searchCookies) &&
            searchCookies?.slice(0, 6)?.map((cookies) => {
              return (
                <React.Fragment key={cookies}>
                  {cookies?.length > 0 && (
                    <React.Fragment>
                      <p
                        className={cookies ? "search-history" : ""}
                        onClick={(e) => handleAddSearch(cookies, e)}
                      >
                        {cookies}
                        <Image
                          src={close}
                          alt="close"
                          className={"close-image"}
                          onClick={(e) => handleRemoveCookies(cookies, e)}
                        />
                      </p>
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchView;
