"use client";
import React, { useLayoutEffect, useRef, useState } from "react";
import VerifyAccountView from "./VerifyAccountView";
import { isEmpty } from "@/src/utils";
import {
  ALPHABET_REGEX,
  NUMBER_REGEX,
  PHONE_REQUIRED,
  SPACE_REGEX,
} from "@/src/const/primitive";
import { COUNTRY_LIST_PHONE_CODE } from "@/src/const/country";
import { VALIDATION, VERIFY_ACCOUNT_FIELDS } from "@/src/const/nonPrimitive";
import {
  phoneNumberAlreadyExists,
  verifyUserAccount,
} from "@/src/redux/actions";
import { useDispatch } from "react-redux";
import { useRouter, useSearchParams } from "next/navigation";
import { addWebsiteValidation } from "@/src/utils/validation/validation";

const VerifyAccountContainer = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    country: "IN",
  });
  const [userDetailsError, setUserDetailsError] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneCountry, setPhoneCountry] = useState("IN");
  const [phoneLength, setPhoneLength] = useState(10);
  const [isStatus, setIsStatus] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const router = useRouter();
  const getRegionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const searchParams = useSearchParams();
  const queryToken = searchParams.get("token");
  const queryEmail = searchParams.get("emailId");

  useLayoutEffect(() => {
    if (!queryToken) {
      router.push("/");
    }
  }, []);

  const handleMobileChange = (name) => {
    const phoneNumberLength = COUNTRY_LIST_PHONE_CODE?.find(
      (obj) => name === obj?.name
    );
    setPhoneCode(phoneNumberLength?.code);
    setPhoneCountry(name);
    setUserDetails({
      ...userDetails,
      phoneNumber: "",
      country: name,
    });
  };

  const handlePhoneNumberChange = (e) => {
    let { name, value } = e.target;
    value = value.replace(name === "phoneNumber" ? ALPHABET_REGEX : "", "");
    const phoneNumberLength = COUNTRY_LIST_PHONE_CODE?.find(
      (obj) => phoneCountry === obj?.name
    );
    if (isEmpty(value)) {
      setUserDetailsError({
        ...userDetailsError,
        phoneNumber: PHONE_REQUIRED,
      });
    } else if (value?.length !== phoneNumberLength?.maxLength) {
      setUserDetailsError({
        ...userDetailsError,
        phoneNumber: `Phone number must be ${phoneNumberLength?.maxLength} digits`,
      });
    } else {
      setUserDetailsError({
        ...userDetailsError,
        phoneNumber: "",
      });
    }
    setPhoneLength(phoneNumberLength?.maxLength);
    setUserDetails({ ...userDetails, phoneNumber: value });
  };

  const handleProfileVerifyInputChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    value = value.replace(
      name === "firstName" || name === "lastName"
        ? NUMBER_REGEX
        : name === "phoneNumber"
          ? ALPHABET_REGEX
          : "",
      ""
    );
    value = value?.replace(SPACE_REGEX, "");
    const profileDetailsObj = {
      ...userDetails,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX),
    };
    value?.length < LAST_INDEX && setUserDetails(profileDetailsObj);
  };

  const handleAlreadyExists = async () => {
    const joinPhoneCode = `${phoneCode}-${userDetails?.phoneNumber}`;
    let phoneNumber = {
      phoneNo: joinPhoneCode,
      country: userDetails?.country,
    };
    const data = await dispatch(phoneNumberAlreadyExists(phoneNumber));
    if (data?.exists) {
      setUserDetailsError({
        ...userDetailsError,
        phoneNumber: "Phone number already exists",
      });
      setIsAlreadyExist(true);
    } else {
      setUserDetailsError({
        ...userDetailsError,
        phoneNumber: "",
      });
      setIsAlreadyExist(false);
    }
  };

  const handleProfileFocus = (name, maxLength) => {
    const { errors } = addWebsiteValidation(
      name,
      userDetails,
      userDetailsError,
      true
    );
    if (
      userDetails[name]?.length === maxLength &&
      userDetailsError[name] !== VALIDATION[name]?.validMsg
    ) {
      errors[name] = "";
      if (isEmpty(errors?.phoneNumber)) {
        handleAlreadyExists();
        return;
      }
    } else {
      if (name === "phoneNumber") {
        if (isEmpty(userDetails?.phoneNumber)) {
          errors[name] = PHONE_REQUIRED;
        } else if (userDetails?.phoneNumber?.length !== maxLength) {
          errors[name] = `Phone number must be ${maxLength} digits`;
        }
        if (isEmpty(errors?.phoneNumber)) {
          handleAlreadyExists();
          return;
        }
      }
    }
    setUserDetailsError(errors);
  };

  const handleProfileSaveChanges = async () => {
    if (queryToken && queryEmail) {
      VERIFY_ACCOUNT_FIELDS?.forEach((ele) => {
        const name = ele?.name?.toLowerCase();
        if (!isEmpty(inputRef.current)) {
          if (
            isEmpty(userDetails[name]) ||
            !isEmpty(userDetailsError[name]) ||
            isEmpty(userDetails?.phoneNumber)
          ) {
            inputRef.current.focus();
          }
        }
      });
      if (
        Object.values(userDetails).every((value) => value) &&
        Object.values(userDetailsError).every((value) => !value) &&
        !isAlreadyExist
      ) {
        setIsStatus(true);
        const joinPhoneCode = `${phoneCode}-${userDetails?.phoneNumber}`;
        const profilePayload = {
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          phoneNo: joinPhoneCode,
          country: userDetails?.country,
        };
        await dispatch(
          verifyUserAccount(profilePayload, queryToken, queryEmail)
        );
        router.push("/");
        setIsStatus(false);
      }
    }
  };

  return (
    <VerifyAccountView
      {...{
        inputRef,
        userDetails,
        userDetailsError,
        getRegionNames,
        phoneLength,
        handleMobileChange,
        handlePhoneNumberChange,
        handleProfileVerifyInputChange,
        handleProfileFocus,
        handleProfileSaveChanges,
        isStatus,
      }}
    />
  );
};

export default VerifyAccountContainer;
