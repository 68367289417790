"use client";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import Link from "next/link";
import React, { useState } from "react";
import "../../../../../styles/support/support.scss";
import { BackButtonComponent } from "@/src/components/CommonComponents";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Establishing and Managing Your Uxvortex Profile",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          To initiate a Uxvortex account, visit our Sign-In/Sign-Up model.
          During the account creation, you'll pick a Uxvortex username,
          lastname, contact number and commence building your profile to give it
          a distinctive touch.
        </p>
        <p className={"profile_details_text"}>
          This process typically takes around five minutes. After creating your
          username and password, you can pause the setup and resume it at any
          time by visiting:
          <Link href={"/profile"} className={"getting_start_link"}>
            Getting Started
          </Link>
          .
        </p>
        <ul className={"profile_crafting_text_main"}>
          <li>Crafting Your Uxvortex Profile:</li>
          <ol>
            <li>
              After account verification user will be able to edit profile photo
              of his/her choice
            </li>
          </ol>
          <li>
            Shot Requirements :
            <ol>
              <li>File size should not exceed 500KB.</li>
              <li>Accepted formats: PNG, JPG or webp.</li>
              <li>File size should not exceed 500KB.</li>
            </ol>
          </li>
        </ul>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Removing a Uxvortex account",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          To remove your Uxvortex account, log in, go to Profile page click on
          delete icon on the right side of the box (User can remove his profile
          only if his profile is verified), an Email confirmation for deleting
          an account is necessary to delete again from the mail id of the user
          which will confirm his deletion of account.
        </p>
        <p className={"profile_details_paragraph"}>
          Keep in mind that deleted accounts cannot be recovered.
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Uxvortex Account Recovery",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          If you're encountering difficulties accessing your Uxvortex account
          due to forgotten login details, fret not! Refer to the instructions
          below to regain entry into your account.
        </p>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            I no longer have access to the social login for my account. What
            should I do?
          </h1>
          <p className={"account_answers"}>
            When you sign up for Uxvortex through Gmail, you also link an email
            address and password. Utilize these credentials to log in to your
            Uxvortex profile, even if you can't access your social login
            account. If you've forgotten the email or password, please see
            below.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            I forgot my password. What should I do?
          </h1>
          <p className={"account_answers"}>
            Reset it using our
            <Link href={"/forgot-password"} className={"forgot_pass_link"}>
              Forgot Password
            </Link>
            tool! Ensure you enter the same email address registered with
            Uxvortex. If issues persist, consider the following tips:
          </p>
          <ul className={"forgot_password_details"}>
            <li>Verify you're using the correct email address.</li>
            <li>Passwords are case-sensitive.</li>
            <li>
              Eliminate any extra spaces in the password field. If difficulties
              persist, clear your browsing data (history, cookies, and cache),
              and disable browser extensions or password tools.
            </li>
          </ul>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            I can't remember the email associated with my account:
          </h1>
          <p className={"account_answers"}>
            If uncertain about the email linked to your Uxvortex account, reach
            out to us!!
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: "Username Guidelines",
    children: (
      <div className={"profile_management_main"}>
        <p className={"profile_details_text"}>
          Uxvortex usernames are allocated on a first-come, first-served basis
          and are intended for immediate and active utilization. Users are not
          allowed to passively reserve a username for future use.
        </p>
        <p className={"profile_details_text"}>
          Inactive Uxvortex accounts may be subject to renaming or removal at
          our discretion. Active accounts will not be renamed or removed except
          in cases of name squatting, which is explicitly forbidden.
        </p>
        <p className={"profile_details_text"}>
          If you believe an account is inactive and wishes to request its
          username, please contact us. Keep in mind that not all activity on
          Uxvortex is publicly visible.
        </p>
      </div>
    ),
    style: panelStyle,
  },
];
const ProfileManageMentView = () => {
  const [isCollapsed, setIsCollapsed] = useState(["1", "2"]);
  const panelStyle = {
    marginBottom: 24,
    border: "none",
    maxWidth: "800px",
    margin: "0 auto",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: isCollapsed?.length <= 0 ? "calc(100vh - 374px)" : "auto",
        // }}
        items={getItems(panelStyle)}
        onChange={(e) => setIsCollapsed(e)}
        className={`${"support_section_page_main"} ${
          isCollapsed?.length >= 1 ? "open" : "close"
        }`}
      />
    </div>
  );
};

export default ProfileManageMentView;
