import { Form } from "antd";
import ReactFlagsSelect from "react-flags-select";
import "../../../../../../styles/submitWebsite/websiteSubmit.scss";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@/src/components/CommonComponents";
import { COUNTRY_LIST_PHONE_CODE } from "@/src/const/country";
import { CHECKBOX_FIELD } from "@/src/const/nonPrimitive";

const DetailedInformationView = ({
  detailedInfoError,
  handleCheckBoxChange,
  handleSelectCountry,
  categoryData,
  stylesData,
  submitWebsiteData,
  handleSubmitWebsitePrevStep,
  handleSubmitWebsiteNextStep,
  current,
}) => {
  return (
    <div className={"submit-details-form-main"}>
      <div className={"submit-form-wrap"}>
        <Form.Item
          className={`${"submit-form-main"} ${"fade-up-animation1"} ${"country-dropdown"}`}
        >
          <div className={"form-details"}>
            <p className={"details-number"}>05</p>
            <label className={"form-title"}>{"Country*"}</label>
          </div>
          <div>
            <ReactFlagsSelect
              name="country"
              countries={COUNTRY_LIST_PHONE_CODE.map((obj) => obj?.name)}
              className={"country-input"}
              onSelect={(code) => handleSelectCountry(code)}
              selected={submitWebsiteData?.country}
              searchable
            />
          </div>
          <p className={"website-error-message"}>
            {detailedInfoError?.country}
          </p>
        </Form.Item>
      </div>
      {CHECKBOX_FIELD?.map((obj) => {
        const options =
          obj?.name?.toLowerCase() === "category".toLowerCase()
            ? categoryData || []
            : stylesData || [];
        return (
          <Form.Item
            key={obj?.number}
            className={`${"industry-styles-wrap"} ${[obj?.className]}`}
          >
            <div className={"industry-styles-main"}>
              <p className={"label-number"}>{obj?.number}</p>
              <label className={"industry-styles-title"}>{obj?.label}</label>
            </div>
            <div className={"list-wrap"}>
              {options?.map((ele) => {
                let a =
                  obj?.name?.toLowerCase() === "category".toLowerCase()
                    ? ele?.categoryName
                    : ele?.styleName;
                return (
                  <div className={"industry-styles-checkbox-main"} key={a}>
                    <CheckBoxComponent
                      {...{
                        obj: ele,
                        value: a,
                        name: obj?.name,
                        submitWebsiteData,
                        handleCheckBoxChange,
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <p className={"website-error-message"}>
              {detailedInfoError?.[obj?.name]}
            </p>
          </Form.Item>
        );
      })}

      <div className={`${"submit-button-main"} ${"fade-up-animation4"}`}>
        {current > 0 && (
          <ButtonComponent
            handleClick={() => handleSubmitWebsitePrevStep()}
            classNames={"preview-btn"}
            btnText={"Previous"}
          />
        )}
        <ButtonComponent
          btnText={"Next"}
          classNames={"next-btn"}
          handleClick={handleSubmitWebsiteNextStep}
        />
      </div>
    </div>
  );
};

export default DetailedInformationView;
