import React, { useState } from "react";
import FinishingUpView from "./FinishingUpView";
import { VALIDATION } from "@/src/const/nonPrimitive";

const FinishingUpContainer = ({
  isStatus,
  websiteError,
  setData,
  data,
  setSubmitWebsiteData,
  fontFamilyData,
  fontsArray,
  submitWebImage,
  submitWebsiteData,
  handleGetWebScapDetails,
  setWebsiteError,
  image,
  setImage,
  handleSubmitWebsitePrevStep,
  handleSubmitWebsiteData,
  current,
  setImageSize,
}) => {
  const [isPreviewImage, setIsPreviewImage] = useState(false);
  const updateFontsRecords = (records, name, value, isSelected) => {
    return records?.map((obj) => {
      if (value.includes(obj?.fontName)) {
        return {
          ...obj,
          isSelected,
        };
      } else {
        return obj;
      }
    });
  };
  const handleFontChange = (val) => {
    const name = "fonts";
    const errorsToUpdate = {};
    let checkedVal;
    let disabledData;
    if (val?.length > 0) {
      checkedVal = {
        ...submitWebsiteData,
        [name]: val,
      };
      disabledData = {
        ...data,
        fontsRecord: updateFontsRecords(data?.fontsRecord, name, val, true),
      };
    } else {
      if (val?.length === 0) {
        checkedVal = {
          ...submitWebsiteData,
          [name]: val,
        };
      } else {
        checkedVal = {
          ...submitWebsiteData,
          [name]: submitWebsiteData?.[name]?.filter((ele) => ele !== val),
        };
      }

      disabledData = {
        ...data,
        [`${name}Record`]: updateFontsRecords(
          data?.[`${name}Record`],
          name,
          val,
          false
        ),
      };
    }

    if (checkedVal[name]?.length <= 0) {
      errorsToUpdate[name] = VALIDATION?.[name]?.requiredMsg;
    } else if (checkedVal[name]?.length > VALIDATION?.[name]?.maxLength) {
      errorsToUpdate[name] = VALIDATION?.[name]?.maxLengthMsg;
    } else if (checkedVal[name]?.length < VALIDATION?.[name]?.minLength) {
      errorsToUpdate[name] = VALIDATION?.[name]?.minLengthMsg;
    } else {
      errorsToUpdate[name] = "";
    }
    const isMaxSelected = val?.length === VALIDATION?.[name]?.maxLength;
    disabledData = {
      ...data,
      [`${name}Record`]: disabledData?.[`${name}Record`]?.map((obj) => ({
        ...obj,
        isDisabled: isMaxSelected,
      })),
    };
    setData(disabledData);
    setWebsiteError({
      ...websiteError,
      ...errorsToUpdate,
      [name]: errorsToUpdate[name],
    });
    if (checkedVal[name]?.length <= VALIDATION?.[name]?.maxLength) {
      setSubmitWebsiteData(checkedVal);
    }
  };
  const handleImageFileChange = async (e) => {
    //eslint-disable-next-line prefer-destructuring
    const imageSs = e.originFileObj;
    setImage(imageSs);
    setImageSize(e?.size);
    if (e?.size > 10485760) {
      setWebsiteError({
        ...websiteError,
        image: "Image Size  must be 10 mb",
      });
    } else {
      setWebsiteError({
        ...websiteError,
        image: "",
      });
    }
  };
  const handleCancelPreviewModel = () => {
    setIsPreviewImage(false);
  };
  const handlePreviewImage = () => {
    setIsPreviewImage(true);
  };
  return (
    <FinishingUpView
      {...{
        isStatus,
        websiteError,
        handleFontChange,
        handleImageFileChange,
        handlePreviewImage,
        handleGetWebScapDetails,
        fontFamilyData,
        fontsArray,
        submitWebImage,
        submitWebsiteData,
        image,
        handleCancelPreviewModel,
        isPreviewImage,
        handleSubmitWebsitePrevStep,
        handleSubmitWebsiteData,
        current,
      }}
    />
  );
};

export default FinishingUpContainer;
