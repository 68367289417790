import React, { useEffect, useRef, useState } from "react";
import FormView from "./FormView";
import { INPUT_FIELD_NAME, VALIDATION } from "@/src/const/nonPrimitive";
import { isEmpty } from "@/src/utils";
import { websiteAlreadyExistsURL } from "@/src/redux/actions";
import { SPACE_REGEX } from "@/src/const/primitive";
import { useDispatch } from "react-redux";
import { addWebsiteValidation } from "@/src/utils/validation/validation";

const FormContainer = ({
  setSubmitWebsiteData,
  submitWebsiteData,
  setIsAlreadyExist,
  handleSubmitWebsitePrevStep,
  current,
  setCurrent,
  isAlreadyExist,
  setIsPrevious,
  token,
  setIsAuthModal,
  profileData,
}) => {
  const [formDetails, setFormDetails] = useState({
    agencyName: "",
    websiteUrl: "",
    websiteTitle: "",
    description: "",
  });
  const [formDetailsError, setFormDetailsError] = useState({});
  const [previousPayload, setPreviousPayload] = useState(null);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const handleScrollTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };
  const handleWebsiteInputChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    value = value?.replace(name === "websiteUrl" ? SPACE_REGEX : "", "");
    value = value.trimStart();
    const data = formDetails;
    const websiteData = {
      ...data,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX) || "",
    };
    if (value?.length <= LAST_INDEX - 1) {
      setFormDetails(websiteData);
      setSubmitWebsiteData(websiteData);
    }
  };
  const handleAlreadyExists = async () => {
    const regex = /(\/)$/;
    const newUrl = formDetails?.websiteUrl.replace(regex, "");
    let website_url_obj = {
      websiteUrl: newUrl,
    };
    if (previousPayload?.websiteUrl !== newUrl) {
      setPreviousPayload(website_url_obj);
      const data = await dispatch(websiteAlreadyExistsURL(website_url_obj));
      if (data?.isExist) {
        setFormDetailsError({
          ...formDetailsError,
          websiteUrl: "Website URL is already exists",
        });
        setIsAlreadyExist(true);
      } else {
        setFormDetailsError({
          ...formDetailsError,
          websiteUrl: "",
        });
        setIsAlreadyExist(false);
      }
    }
  };
  const handleFocus = (e, name, maxLength) => {
    const { errors } = addWebsiteValidation(
      name,
      formDetails,
      formDetailsError,
      true
    );
    if (name === "websiteUrl" && isEmpty(errors?.websiteUrl)) {
      handleAlreadyExists();
      return;
    }
    if (
      formDetails[name]?.length === maxLength &&
      formDetailsError[name] !== VALIDATION[name]?.validMsg
    ) {
      errors[name] = "";
    }
    setFormDetailsError(errors);
  };
  const handleSubmitWebsiteNextStep = () => {
    if (token) {
      if (profileData?.auth?.isVerified === false) {
        handleScrollTop();
      } else {
        const errorsToUpdate = {};
        INPUT_FIELD_NAME?.forEach((ele) => {
          const name = ele?.name;
          if (!isEmpty(inputRef.current)) {
            if (
              isEmpty(formDetails[name]) ||
              !isEmpty(formDetailsError[name])
            ) {
              inputRef.current.focus();
              if (isAlreadyExist) {
                errorsToUpdate["websiteUrl"] = "Website URL is already exists";
              } else {
                errorsToUpdate["websiteUrl"] = "";
              }
            } else if (name === "websiteUrl") {
              if (isAlreadyExist) {
                errorsToUpdate["websiteUrl"] = "Website URL is already exists";
              } else {
                errorsToUpdate["websiteUrl"] = "";
              }
            }
          }
        });
        setFormDetailsError({
          ...formDetailsError,
          ...errorsToUpdate,
          websiteUrl: errorsToUpdate?.websiteUrl,
        });
        if (
          Object.values(formDetails).every((value) => value) &&
          Object.values(formDetailsError).every((value) => !value) &&
          Object.values(errorsToUpdate).every((value) => !value) &&
          !isAlreadyExist
        ) {
          setCurrent(current + 1);
          setIsPrevious(false);
        }
      }
    } else {
      setIsAuthModal(true);
    }
  };
  useEffect(() => {
    setFormDetails({
      ...formDetails,
      agencyName: submitWebsiteData?.agencyName,
      websiteUrl: submitWebsiteData?.websiteUrl,
      websiteTitle: submitWebsiteData?.websiteTitle,
      description: submitWebsiteData?.description,
    });
  }, [submitWebsiteData]);

  return (
    <FormView
      {...{
        formDetailsError,
        handleWebsiteInputChange,
        handleAlreadyExists,
        submitWebsiteData: formDetails,
        inputRef,
        handleSubmitWebsitePrevStep,
        current,
        handleSubmitWebsiteNextStep,
        handleFocus,
      }}
    />
  );
};

export default FormContainer;
