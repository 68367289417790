"use client";
import WebsiteCardContainer from "@/src/components/CommonComponents/WebsiteCards/WebsiteCardContainer";
import { topTenRecord } from "@/src/redux/reducers/topWebReducer/TopWebReducer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../styles/paidCard/paidCard.scss";
import { topTenWebsites } from "@/src/redux/actions";
import { isEmpty } from "@/src/utils";

const TopTenWebView = ({ token, isScreenShotOpen }) => {
  const { topWebsiteData } = useSelector((state) => state.topWebsites);
  const [isLoadingTopWebsite, setIsLoadingTopWebsite] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(topWebsiteData)) {
      setIsLoadingTopWebsite(true);
      const TopTenWebsiteData = async () => {
        const TopTenData = await topTenWebsites();
        dispatch(topTenRecord(TopTenData?.data));
        await setIsLoadingTopWebsite(false);
      };
      TopTenWebsiteData();
    }
  }, []);
  return (
    <React.Fragment>
      <WebsiteCardContainer
        {...{
          token,
          data: topWebsiteData,
          slidesToShowImage: isScreenShotOpen ? 1 : 3,
          isScreenShotOpen,
          isCarousel: true,
          isLoadingTopWebsite,
        }}
      />
    </React.Fragment>
  );
};

export default TopTenWebView;
