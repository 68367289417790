import React from "react";
import "../../../../../../styles/collectionByName/collectionByName.scss";
import Image from "next/image";

const CollectionByName = ({ collectionSliceData }) => {
  return (
    <div>
      <div className={"collection_by_name_main"}>
        <div className={"collection_by_name"}>
          <ul
            className={`${
              collectionSliceData?.length === 1 ? "collection_web_1" : ""
            } ${"collection_by_name_wrap"}`}
          >
            {collectionSliceData &&
              collectionSliceData?.map((obj) => {
                return (
                  <li
                    key={obj?.collectionId}
                    className={`${
                      collectionSliceData?.length === 4
                        ? "collection_web_4"
                        : collectionSliceData?.length === 3
                          ? "collection_web_3"
                          : collectionSliceData?.length === 2
                            ? "collection_web_2"
                            : "collection_web"
                    }`}
                  >
                    <Image
                      src={obj?.screenShotImg}
                      alt="collection-image"
                      width={500}
                      height={200}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CollectionByName;
