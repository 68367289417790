"use client";
import React, { useEffect } from "react";
import SettingsView from "./SettingsView";
import {
  getSessionDetails,
  settingsModuleChange,
} from "@/src/redux/reducers/slices";
import { useDispatch, useSelector } from "react-redux";
import { getSessionData } from "@/src/redux/actions";

const SettingsContainer = () => {
  const { settingModuleName } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const handleChangeSettingsName = async (name) => {
    await dispatch(settingsModuleChange(name));
  };

  const getSessionDatas = async () => {
    const socialData = await getSessionData();
    await dispatch(getSessionDetails(socialData?.data));
  };

  useEffect(() => {
    getSessionDatas();
  }, []);

  return <SettingsView {...{ handleChangeSettingsName, settingModuleName }} />;
};

export default SettingsContainer;
