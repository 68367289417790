"use client";
import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "../../../../../styles/support/support.scss";
import { BackButtonComponent } from "@/src/components/CommonComponents";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "About the API",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            The Uxvortex API enables developers to access specific areas of
            Uxvortex, such as shots, attachments and users.
          </p>
          <ul className={"forgot_password_details"}>
            <li>
              Unfortunately, access to our Popular shots feed is not available
              at this time.
            </li>
            <li>
              Regrettably, we cannot provide support or assistance with
              API-related inquiries.
            </li>
          </ul>
        </div>
      </div>
    ),
    style: panelStyle,
  },
];

const ApplicationAPIView = () => {
  const panelStyle = {
    marginBottom: 24,
    border: "none",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={"1"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: "calc(100vh - 374px)",
        // }}
        className={`${"support_section_page_main"} ${"close"}`}
        items={getItems(panelStyle)}
      />
    </div>
  );
};

export default ApplicationAPIView;
