import React from "react";
import {
  BackButtonComponent,
  ButtonComponent,
  ImageComponent,
  InputComponent,
  SuccessModal,
} from "../../CommonComponents";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { forgotPasswordImage } from "@/public/assets";
import { isEmpty } from "@/src/utils";
import "../../../../styles/password/forgotpassword.scss";
import { FORGOT_PASSWORD_FIELD } from "@/src/const/nonPrimitive";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const ForgotPasswordView = ({
  isModalOpen,
  inputRef,
  forgotPasswordError,
  forgotPassword,
  handleSubmitForgotPassword,
  handlePasswordChange,
  handleValidTokenModal,
  handleForgotEmailKeyDown,
  handlePasswordFocus,
  isSuccessModal,
  setIsSuccessModal,
  isStatus,
  isPasswordShow,
  handleIsShowPasswordInvisible,
  handleIsShowPassword,
}) => {
  return (
    <div className={"forgot_password_wrap"}>
      <div className="row">
        <div className={"forgot_password_left col-xxl-10 col-lg-24"}>
          <Lottie
            loop
            animationData={forgotPasswordImage}
            play
            className={"forgot_password_image"}
          />
        </div>
        <div className={`forgot_password_right common col-xxl-14 col-lg-24`}>
          <div className={"forgot_back_btn"}>
            <BackButtonComponent url="/" />
          </div>
          <div className={"forgot_password_main"}>
            <div className={"forgot_password_title"}>Forgot Password?</div>
            <div className={"forgot_password_description"}>
              Enter your email address and we will send you a link to reset your
              password.
            </div>
            <div className={"forgot_reset_form"}>
              <div className={"reset_password_main"}>
                {FORGOT_PASSWORD_FIELD?.map((obj) => {
                  return (
                    <div
                      key={obj?.label}
                      className={"reset-password-main-input"}
                    >
                      <label className={"reset-password-email-label "}>
                        {obj?.label}
                      </label>
                      <div className={"reset-password-input-border"}>
                        <ImageComponent
                          imageSrc={obj?.image}
                          imageAlt={obj?.label}
                          imageClassName={"reset_inputs-icons"}
                        />
                      </div>
                      <InputComponent
                        classNames={"current_password"}
                        name={obj?.name}
                        type={isPasswordShow[obj?.name] ? "text" : obj?.type}
                        value={forgotPassword?.[obj?.name]}
                        error={forgotPasswordError?.[obj?.name]}
                        handleKeyDown={handleForgotEmailKeyDown}
                        placeholder={obj?.placeholder}
                        maxLength={obj?.maxLength}
                        minLength={obj?.minLength}
                        handleChange={(e) =>
                          handlePasswordChange(e, 0, obj?.maxLength)
                        }
                        inputRef={
                          isEmpty(forgotPassword?.[obj?.name]) ||
                          !isEmpty(forgotPasswordError?.[obj?.name])
                            ? inputRef
                            : null
                        }
                        handleBlur={() =>
                          handlePasswordFocus(obj?.maxLength - 1, obj?.name)
                        }
                      />
                      {isPasswordShow[obj?.name] ? (
                        <EyeOutlined
                          onClick={() =>
                            handleIsShowPasswordInvisible(obj?.name)
                          }
                          className={"eye-icons"}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => handleIsShowPassword(obj?.name)}
                          className={"eye-icons"}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <ButtonComponent
                btnText={isStatus ? <LoadingOutlined /> : "Confirm "}
                handleClick={isStatus ? () => {} : handleSubmitForgotPassword}
                classNames={"forgot_confirm"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={false}
        closable={false}
        className="expired-password-modal"
      >
        <p>OOPS!!! Your Token has expired. Please Re-Enter your email</p>
        <ButtonComponent
          btnText={"Send Email"}
          handleClick={handleValidTokenModal}
          classNames="expired-password-modal-button"
        />
      </Modal>
      {isSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal: isSuccessModal,
            setIsSuccessModal: setIsSuccessModal,
            descriptionText: "Your Password Set Successfully",
            isEmailSend: false,
          }}
        />
      )}
    </div>
  );
};

export default ForgotPasswordView;
