"use client";
import React, { useEffect, useRef, useState } from "react";
import UserWebsitesView from "./UserWebsitesView";
import {
  deleteUserWebsiteData,
  getFilterUserWebsiteData,
  getUserWebsiteData,
  updateUserWebsiteData,
} from "@/src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "@/src/utils";
import {
  VALIDATION,
  USER_EDIT_WEBSITE_FIELD_NAME,
} from "@/src/const/nonPrimitive";
import {
  getUserWebsiteDetails,
  isMyWebsiteEndPage,
  isLoadingWebsite,
  userFilterWebsiteData,
} from "@/src/redux/reducers/slices";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { TWELVE_COUNT } from "@/src/const/primitive";

const UserWebsitesContainer = ({ token }) => {
  const [userWebsiteCardData, setUserWebsiteCardData] = useState([]);
  const [websiteEditData, setWebsiteEditData] = useState({
    agencyName: "",
    title: "",
    description: "",
    category: [],
    style: [],
  });
  const [editImage, setEditImage] = useState("");
  const [userEditData, setUserEditData] = useState({
    isModalEdit: false,
    editId: "",
  });
  const [data, setData] = useState({
    categoryRecords: [],
    styleRecords: [],
  });
  const [websiteError, setWebsiteError] = useState({});
  const [screenFilter, setScreenFilter] = useState("All");
  const [isFilter, setIsFilter] = useState(false);
  const [image, setImage] = useState("");
  const [imageSize] = useState();
  const [isDeleteSuccessModal, setIsDeleteSuccessModal] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isEditSuccessModal, setIsEditSuccessModal] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
  const { userWebsiteData, userFilterWebData } = useSelector(
    (state) => state.profile
  );
  const { isSearch, setIsSearch } = useNav();
  const { categoryData, stylesData } = useSelector(
    (state) => state.categoryAndStyles
  );
  const [deleteWebsite, setDeleteWebsite] = useState({
    isModelOpen: false,
    isConfirm: false,
    urlName: "",
    inputValue: "",
    websiteId: "",
  });
  const dispatch = useDispatch();
  const inputImageRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  const getWebsiteData = async () => {
    dispatch(isLoadingWebsite(true));
    const payload = {
      page: 1,
      limit: TWELVE_COUNT,
      status: "All",
    };
    const userAllWebsiteData = await getUserWebsiteData(payload);
    setUserWebsiteCardData(userAllWebsiteData?.data);
    await dispatch(getUserWebsiteDetails(userAllWebsiteData?.data));
    await dispatch(isMyWebsiteEndPage(userAllWebsiteData?.isEnd));
    await dispatch(isLoadingWebsite(false));
  };
  useEffect(() => {
    getWebsiteData();
  }, []);
  const handleFilterUserWebsite = async (filterVal) => {
    dispatch(isLoadingWebsite(true));
    setScreenFilter(filterVal);
    if (filterVal?.toLowerCase() === "All"?.toLowerCase()) {
      setUserWebsiteCardData(userWebsiteData);
      setIsFilter(false);
      setIsSearch(false);
      await dispatch(isLoadingWebsite(false));
    } else {
      setIsSearch(true);
      setUserWebsiteCardData([]);
      setIsFilter(true);
      const res = await getFilterUserWebsiteData(1, TWELVE_COUNT, filterVal);
      setUserWebsiteCardData(res?.data);
      await dispatch(userFilterWebsiteData(res?.data));
      await dispatch(isLoadingWebsite(false));
    }
  };
  const handleEditUserWebsiteInputChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    const editedWebsiteData = {
      ...websiteEditData,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX) || "",
    };
    value?.length < LAST_INDEX && setWebsiteEditData(editedWebsiteData);
  };
  const updateRecords = (records, name, value, isSelected) => {
    return records?.map((obj) => {
      const key = name === "category" ? "categoryName" : "styleName";
      if (obj?.[key]?.toLowerCase() === value?.toLowerCase()) {
        return {
          ...obj,
          isSelected,
        };
      } else {
        return obj;
      }
    });
  };
  const handleCheckBoxChange = (e, value, name) => {
    const errorsToUpdate = {};
    let checkedVal;
    let disabledData;
    if (e.target.checked) {
      if (websiteEditData[name]?.includes(value)) {
        checkedVal = {
          ...websiteEditData,
          [name]: websiteEditData[name]?.includes(value)
            ? websiteEditData?.[name]?.filter((ele) => ele !== value)
            : [...websiteEditData?.[name], value],
        };
        disabledData = {
          ...data,
          categoryRecords: updateRecords(
            data?.categoryRecords,
            name,
            value,
            false
          ),
          styleRecords: updateRecords(data?.styleRecords, name, value, false),
        };
      } else {
        checkedVal = {
          ...websiteEditData,
          [name]: websiteEditData[name]?.includes(value)
            ? websiteEditData?.[name]?.filter((ele) => ele !== value)
            : [...websiteEditData?.[name], value],
        };
        disabledData = {
          ...data,
          categoryRecords: updateRecords(
            data?.categoryRecords,
            name,
            value,
            true
          ),
          styleRecords: updateRecords(data?.styleRecords, name, value, true),
        };
      }
    } else {
      checkedVal = {
        ...websiteEditData,
        [name]: websiteEditData?.[name]?.filter((ele) => ele !== value),
      };
      disabledData = {
        ...data,
        [`${name}Records`]: updateRecords(
          data?.[`${name}Records`],
          name,
          value,
          false
        ),
      };
    }
    if (checkedVal[name]?.length <= 0) {
      errorsToUpdate[name] = VALIDATION?.[name]?.requiredMsg;
    } else if (checkedVal[name]?.length < 3) {
      errorsToUpdate[name] = VALIDATION?.[name]?.minLengthMsg;
    } else if (checkedVal[name]?.length > 5) {
      errorsToUpdate[name] = VALIDATION?.[name]?.maxLengthMsg;
    } else {
      errorsToUpdate[name] = "";
    }
    const isMaxSelected = checkedVal[name]?.length === 5;
    disabledData = {
      ...data,
      [`${name}Records`]: disabledData?.[`${name}Records`]?.map((obj) => ({
        ...obj,
        isDisabled: isMaxSelected,
      })),
    };
    setData(disabledData);
    setWebsiteError({
      ...websiteError,
      ...errorsToUpdate,
      [name]: errorsToUpdate[name],
    });
    if (checkedVal[name]?.length <= 5) {
      setWebsiteEditData(checkedVal);
    }
  };
  const handleImageFileChange = async (e) => {
    //eslint-disable-next-line prefer-destructuring
    const imageSs = e.target.files[0];
    setImage(imageSs);
    if (imageSs?.size > 10485760) {
      setWebsiteError({
        ...websiteError,
        image: "Image Size  must be 10 mb",
      });
    } else {
      setWebsiteError({
        ...websiteError,
        image: "",
      });
    }
  };
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };
  const handleUserWebsiteDelete = async (e, id, url) => {
    e.stopPropagation();
    setDeleteWebsite({
      ...deleteWebsite,
      websiteId: id,
      isModelOpen: true,
      urlName: url,
    });
  };
  const handleUserWebsiteEdit = async (e, id) => {
    e.stopPropagation();
    const editRecord = userWebsiteCardData?.find(
      (obj) => obj?.websiteId === id
    );
    setEditImage(editRecord?.screenShotImg);
    const payload = {
      agencyName: editRecord?.agencyName,
      title: editRecord?.title,
      description: editRecord?.description,
      category:
        editRecord?.category?.split(",").map((item) => item.trim()) || [],
      style: editRecord?.style?.split(",").map((item) => item.trim()),
    };
    const a = data?.categoryRecords?.map((obj) => {
      if (payload?.category?.includes(obj?.categoryName)) {
        return {
          ...obj,
          isSelected: true,
          isDisabled: payload?.category?.length === 5 && true,
        };
      } else {
        return {
          ...obj,
          isDisabled: payload?.category?.length === 5 && true,
        };
      }
    });
    const b = data?.styleRecords?.map((obj) => {
      if (payload?.style?.includes(obj?.styleName)) {
        return {
          ...obj,
          isSelected: true,
          isDisabled: payload?.style?.length === 5 && true,
        };
      } else {
        return {
          ...obj,
          isDisabled: payload?.style?.length === 5 && true,
        };
      }
    });
    setData({
      ...data,
      categoryRecords: a,
      styleRecords: b,
    });
    setWebsiteEditData(payload);
    setUserEditData({
      isModalEdit: true,
      editId: id,
    });
  };
  const handleUserEditCancel = () => {
    setUserEditData({
      isModalEdit: false,
      editId: "",
    });
    setImage("");
    setEditImage("");
    setScreenFilter(screenFilter);
    setWebsiteError({});
    setData({
      ...data,
      categoryRecords: data?.categoryRecords?.map((obj) => {
        return { ...obj, isSelected: false, isDisabled: false };
      }),
      styleRecords: data?.styleRecords?.map((obj) => {
        return { ...obj, isSelected: false, isDisabled: false };
      }),
    });
    setIsSearch(false);
    setUserWebsiteCardData(isFilter ? userFilterWebData : userWebsiteData);
  };
  const handleUserWebFocus = (e, name, maxLength) => {
    const { errors } = addWebsiteValidation(
      name,
      websiteEditData,
      websiteError,
      true
    );
    if (
      websiteEditData[name]?.length === maxLength &&
      websiteError[name] !== VALIDATION[name]?.validMsg
    ) {
      errors[name] = "";
    }
    setWebsiteError(errors);
  };
  const handleSaveDeleteWebsite = async () => {
    const filterData = userWebsiteCardData?.filter(
      (obj) => obj?.websiteId !== deleteWebsite?.websiteId
    );
    setIsDeleteSuccessModal(true);
    setUserWebsiteCardData(filterData);
    setDeleteWebsite((prev) => ({
      ...prev,
      isModelOpen: false,
      inputValue: "",
      urlName: "",
      websiteId: "",
      isConfirm: false,
    }));
    await dispatch(deleteUserWebsiteData(deleteWebsite?.websiteId));
  };
  const handleDeleteWebsiteInputChange = (e) => {
    const { value } = e.target;
    const name = deleteWebsite?.urlName;
    if (name === value) {
      setDeleteWebsite((prev) => ({
        ...prev,
        isConfirm: true,
        inputValue: value,
      }));
    } else {
      setDeleteWebsite((prev) => ({
        ...prev,
        isConfirm: false,
        inputValue: value,
      }));
    }
  };
  const handleCancelWebsite = () => {
    setDeleteWebsite((prev) => ({
      ...prev,
      isModelOpen: false,
      inputValue: "",
    }));
  };
  const handleUserWebsiteUpdate = async () => {
    const errorsToUpdate = {};
    if (isEmpty(image) && isEmpty(editImage)) {
      errorsToUpdate["image"] = "Image is Required";
    } else if (imageSize > 10485760) {
      errorsToUpdate["image"] = "Image Size  must be 10 mb";
    }
    USER_EDIT_WEBSITE_FIELD_NAME?.forEach((ele) => {
      const name = ele?.name?.toLowerCase();
      if (isEmpty(websiteEditData[name])) {
        errorsToUpdate[name] = VALIDATION[name]?.requiredMsg;
      } else if (
        VALIDATION[name]?.maxLength &&
        websiteEditData[name]?.length > VALIDATION[name]?.maxLength
      ) {
        errorsToUpdate[name] = VALIDATION[name]?.maxLengthMsg;
      } else if (
        VALIDATION[name]?.minLength &&
        websiteEditData[name]?.length < VALIDATION?.[name]?.minLength
      ) {
        errorsToUpdate[name] = VALIDATION[name]?.minLengthMsg;
      } else {
        errorsToUpdate[name] = "";
      }
    });
    setWebsiteError({
      ...websiteError,
      ...errorsToUpdate,
      fonts: errorsToUpdate?.fonts,
      image: errorsToUpdate?.image,
    });
    if (
      Object.values(websiteEditData).every((value) => value) &&
      Object.values(websiteError).every((value) => !value) &&
      Object.values(errorsToUpdate).every((value) => !value)
    ) {
      setIsStatus(true);
      const formData = new FormData();
      if (image) {
        formData.append("screenshot", image ? image : "");
      }
      const payload = {
        agencyName: websiteEditData?.agencyName,
        title: websiteEditData?.title,
        description: websiteEditData?.description,
        category: websiteEditData?.category?.join(","),
        style: websiteEditData?.style?.join(","),
        profileId: profileData?.profileId,
      };
      formData.append("screenshot", JSON.stringify(payload));
      const response = await dispatch(
        updateUserWebsiteData(userEditData?.editId, formData)
      );
      setIsStatus(false);
      const filterData = isFilter ? userFilterWebData : userWebsiteData;
      const updatedData = filterData?.filter(
        (obj) => obj?.websiteId !== userEditData?.editId
      );
      setUserWebsiteCardData(updatedData);
      if (response?.status === 200) {
        setIsEditSuccessModal(true);
        setUserEditData({ ...userEditData, isModalEdit: false });
        setImage("");
      }
    }
  };
  useEffect(() => {
    const sortByCategoryName = (a, b) =>
      a.categoryName > b.categoryName ? 1 : -1;
    const sortByStyleName = (a, b) => (a.styleName > b.styleName ? 1 : -1);
    if (
      Array.isArray(categoryData) &&
      categoryData?.length > 0 &&
      Array.isArray(stylesData) &&
      stylesData?.length > 0
    ) {
      const categorySortedData = [...categoryData].sort(sortByCategoryName);
      const styleSortedData = [...stylesData].sort(sortByStyleName);

      setData((prevData) => ({
        ...prevData,
        categoryRecords: categorySortedData.map((obj) => ({
          ...obj,
          isSelected: false,
          isDisabled: false,
        })),
        styleRecords: styleSortedData.map((obj) => ({
          ...obj,
          isSelected: false,
          isDisabled: false,
        })),
      }));
    }
  }, [categoryData, stylesData]);
  return (
    <UserWebsitesView
      {...{
        image,
        deleteWebsite,
        websiteEditData,
        categoryData: data?.categoryRecords,
        stylesData: data?.styleRecords,
        userEditData,
        handleUserWebsiteDelete,
        handleUserWebsiteEdit,
        handleUserEditCancel,
        handleUserWebsiteUpdate,
        handleEditUserWebsiteInputChange,
        handleUserWebFocus,
        websiteError,
        handleFilterUserWebsite,
        screenFilter,
        inputImageRef,
        editImage,
        handleDeleteWebsiteInputChange,
        handleCancelWebsite,
        handleSaveDeleteWebsite,
        handleImageFileChange,
        handleUploadImage,
        isStatus,
        isDeleteSuccessModal,
        setIsDeleteSuccessModal,
        isEditSuccessModal,
        setIsEditSuccessModal,
        handleCheckBoxChange,
        userWebsiteCardData,
        isSearch,
        token,
      }}
    />
  );
};

export default UserWebsitesContainer;
