/* eslint-disable jsx-a11y/label-has-associated-control */
"use client";
import { Form, Input, Select } from "antd";
import "../../../../styles/verifyAccount/verifyAccount.scss";
import React from "react";
import { VERIFY_ACCOUNT_FIELDS } from "@/src/const/nonPrimitive";
import ReactCountryFlag from "react-country-flag";
import { COUNTRY_LIST_PHONE_CODE } from "@/src/const/country";
import { isEmpty } from "@/src/utils";
import { LoadingOutlined } from "@ant-design/icons";
import Image from "next/image";
import { profileIcons, uxVortex } from "@/public/assets";
import { ButtonComponent, InputComponent } from "../../CommonComponents";

const { Option } = Select;
const VerifyAccountView = ({
  inputRef,
  userDetails,
  userDetailsError,
  getRegionNames,
  phoneLength,
  handleMobileChange,
  handlePhoneNumberChange,
  handleProfileVerifyInputChange,
  handleProfileFocus,
  handleProfileSaveChanges,
  isStatus,
}) => {
  const selectBefore = (
    <Select
      defaultValue="IN"
      // value={phoneCountry}
      bordered={false}
      onChange={(e) => handleMobileChange(e)}
    >
      {COUNTRY_LIST_PHONE_CODE?.map((obj) => {
        return (
          <Option key={obj?.name} value={obj?.name}>
            <ReactCountryFlag
              svg
              style={{
                width: "2em",
                height: "1em",
              }}
              countryCode={obj?.name}
            />
            {obj?.code}
          </Option>
        );
      })}
    </Select>
  );
  return (
    <div className={"form_main"}>
      <div className={"website_logo_main"}>
        <Image
          src={uxVortex}
          alt={"uxVortex"}
          height={150}
          width={160}
          className={"logo"}
        />
      </div>
      {/* <BackButtonComponent
        url="/"
        text="Go to home"
        textClass={"go_home_button"}
      /> */}
      <div className={"verify_form_main"}>
        <div className={"verify_profile_title"}>Complete your profile</div>
        <div className={"profile_verify_form_main"}>
          {VERIFY_ACCOUNT_FIELDS?.map((obj) => {
            return (
              <div key={obj?.label} className={"verify_acc_form_main"}>
                <Form.Item className={"form_item"}>
                  <label className={"form_label"}>{obj?.label}</label>
                  <div className={"border"}>
                    <Image
                      src={profileIcons}
                      alt="profileIcons"
                      height={50}
                      width={50}
                      className="email-image"
                    />
                  </div>

                  <InputComponent
                    type={obj?.type}
                    placeholder={obj?.placeholder}
                    classNames={"form_input"}
                    maxLength={obj?.maxLength}
                    name={obj?.name}
                    error={userDetailsError?.[obj?.name]}
                    value={userDetails?.[obj?.name]}
                    handleChange={(e) =>
                      handleProfileVerifyInputChange(e, 0, obj?.maxLength)
                    }
                    handleBlur={() =>
                      handleProfileFocus(obj?.name, obj?.maxLength - 1)
                    }
                    inputRef={
                      isEmpty(userDetails?.[obj?.name]) ||
                      !isEmpty(userDetailsError?.[obj?.name])
                        ? inputRef
                        : null
                    }
                  />
                </Form.Item>
              </div>
            );
          })}
          <div className={"verify_phone_main"}>
            <label className={"phone_form_label"}>Phone Number</label>
            <Input
              name="phoneNumber"
              type="tel"
              addonBefore={selectBefore}
              onChange={(e) => handlePhoneNumberChange(e)}
              className={"verify_phone_input"}
              placeholder={"Enter Phone Number"}
              onBlur={() => handleProfileFocus("phoneNumber", phoneLength)}
              maxLength={phoneLength}
              value={userDetails?.phoneNumber}
              ref={
                isEmpty(userDetails?.phoneNumber) ||
                !isEmpty(userDetailsError?.phoneNumber)
                  ? inputRef
                  : null
              }
            />
            <p className={"verify_phone_error"}>
              {userDetailsError?.phoneNumber}
            </p>
          </div>
          <div className={"verify_btn_main"}>
            {/* <label className={"country_label"}>Country</label> */}
            <Select
              defaultValue={"IN"}
              value={userDetails?.country}
              disabled
              // handleBlur={() => handleProfileFocus("country", 50)}
              className={"country_btn"}
            >
              {COUNTRY_LIST_PHONE_CODE?.map((obj) => {
                return (
                  <Option key={obj?.name} value={obj?.name}>
                    <ReactCountryFlag
                      svg
                      style={{
                        width: "2em",
                        height: "1em",
                      }}
                      countryCode={obj?.name}
                    />
                    {getRegionNames.of(obj?.name)}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <ButtonComponent
          btnText={isStatus ? <LoadingOutlined /> : "Submit"}
          disabled={isStatus ? true : false}
          handleClick={handleProfileSaveChanges}
          classNames={"verify_submit_btn"}
        />
      </div>
    </div>
  );
};

export default VerifyAccountView;
