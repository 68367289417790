"use client";
import React, { useState, useEffect } from "react";
import SubmitView from "./SubmitView";
import { isEmpty } from "@/src/utils";
import {
  INPUT_FIELD_NAME,
  NOTIFICATION_STATUS_KEY,
  VALIDATION,
} from "@/src/const/nonPrimitive";
import { useDispatch, useSelector } from "react-redux";
import useSocket from "@/src/hooks/useSocket";
import { SuccessModal } from "@/src/components/CommonComponents";
import {
  getSubmitWebsiteScreenShotData,
  userPostWebsite,
} from "@/src/redux/actions";
import { addNotification } from "@/src/redux/reducers/slices";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { apiConst } from "@/src/const/apiConst";

const SubmitContainer = ({ token }) => {
  const [submitWebsiteData, setSubmitWebsiteData] = useState({
    agencyName: "",
    websiteUrl: "",
    websiteTitle: "",
    description: "",
    country: "",
    category: [],
    style: [],
    fonts: [],
  });
  const [current, setCurrent] = useState(0);
  const [fontsArray, setFontsArray] = useState([]);
  const [submitWebImage, setSubmitWebImage] = useState();
  const [image, setImage] = useState();
  const [websiteError, setWebsiteError] = useState({});
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [imageSize, setImageSize] = useState();
  const [isPrevious, setIsPrevious] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [screenSize, setScreenSize] = useState(769);
  const [data, setData] = useState({
    categoryRecords: [],
    styleRecords: [],
    fontsRecord: [],
  });
  const { categoryData, stylesData, fontFamilyData } = useSelector(
    (state) => state.categoryAndStyles
  );
  const { profileData } = useSelector((state) => state.profile);
  const { setIsAuthModal } = useNav();
  const dispatch = useDispatch();
  const socket = useSocket();
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);
  const handleGetWebScapDetails = async () => {
    if (token) {
      const regex = /(\/)$/;
      const newUrl = submitWebsiteData?.websiteUrl?.replace(regex, "");
      if (!isEmpty(newUrl)) {
        const regex = /(\/)$/;
        const newUrl = submitWebsiteData?.websiteUrl?.replace(regex, "");
        const response = await dispatch(getSubmitWebsiteScreenShotData(newUrl));
        setIsStatus(false);
        setSubmitWebImage(`${response?.data?.screenShotImg}`);
        let setFont = new Set(response?.data?.fontFamily);
        setFont = Array.from(setFont);
        setFont = setFont?.map((ele) => {
          return {
            fontName: ele,
          };
        });
        setFontsArray(setFont);
      }
    } else {
      setIsAuthModal(true);
    }
  };
  const handleSubmitWebsitePrevStep = () => {
    setCurrent(current - 1);
    setIsPrevious(true);
  };
  const handleSubmitWebsiteData = async () => {
    const errorsToUpdate = {};
    if (isEmpty(submitWebImage)) {
      if (isEmpty(image)) {
        errorsToUpdate["image"] = "Image is Required";
      } else if (imageSize > 10485760) {
        errorsToUpdate["image"] = "Image Size  must be 10 mb";
      }
    }
    INPUT_FIELD_NAME?.forEach((ele) => {
      const name = ele?.name?.toLowerCase();
      if (name === "fonts") {
        if (isEmpty(submitWebsiteData[name])) {
          errorsToUpdate[name] = VALIDATION[name]?.requiredMsg;
        } else if (
          VALIDATION[name]?.maxLength &&
          submitWebsiteData[name]?.length > VALIDATION[name]?.maxLength
        ) {
          errorsToUpdate[name] = VALIDATION[name]?.maxLengthMsg;
        } else if (
          VALIDATION[name]?.minLength &&
          submitWebsiteData[name]?.length < VALIDATION?.[name]?.minLength
        ) {
          errorsToUpdate[name] = VALIDATION[name]?.minLengthMsg;
        } else if (isAlreadyExist) {
          errorsToUpdate["websiteUrl"] = "Website URL is already exists";
        } else {
          errorsToUpdate[name] = "";
        }
      }
    });
    setWebsiteError({
      ...websiteError,
      ...errorsToUpdate,
      fonts: errorsToUpdate?.fonts,
      image: errorsToUpdate?.image,
    });
    if (
      Object.values(submitWebsiteData).every((value) => value) &&
      Object.values(websiteError).every((value) => !value) &&
      Object.values(errorsToUpdate).every((value) => !value) &&
      !isAlreadyExist
    ) {
      setIsStatus(true);
      const formData = new FormData();
      if (!isEmpty(submitWebImage)) {
        formData.append("screenshot", submitWebImage);
      } else {
        formData.append("screenshot", image && image);
      }
      const regex = /(\/)$/;
      const newUrl = submitWebsiteData?.websiteUrl?.replace(regex, "");
      const websitePayload = {
        agencyName: submitWebsiteData?.agencyName,
        websiteUrl: newUrl,
        title: submitWebsiteData?.websiteTitle,
        description: submitWebsiteData?.description,
        country: submitWebsiteData?.country,
        category: submitWebsiteData?.category.join(", "),
        style: submitWebsiteData?.style.join(", "),
        fontFamily: submitWebsiteData?.fonts?.join(","),
        userEmailId: profileData?.auth?.emailId,
        profileId: profileData?.profileId,
      };
      formData.append("screenshot", JSON.stringify(websitePayload));
      const res = await dispatch(userPostWebsite(formData));
      if (res?.status === 201) {
        setIsSuccessModal(true);
        setData({
          ...data,
          categoryRecords: data?.categoryRecords?.map((obj) => {
            return { ...obj, isSelected: false, isDisabled: false };
          }),
          styleRecords: data?.styleRecords?.map((obj) => {
            return { ...obj, isSelected: false, isDisabled: false };
          }),
        });
        setSubmitWebsiteData({
          agencyName: "",
          websiteUrl: "",
          websiteTitle: "",
          description: "",
          country: "",
          category: [],
          style: [],
          fonts: [],
        });
        setImage();
        setSubmitWebImage();
        setFontsArray([]);
        const websiteDetails = res?.data?.data;
        const myWebsite = {
          socketId: profileData?.socketId,
          fullName: `${profileData?.firstName} ${profileData?.lastName}`,
          websiteId: websiteDetails?.websiteId,
          websiteURL: websiteDetails?.websiteUrl,
          websiteName: websiteDetails?.title,
          sender: profileData?.profileId,
          status: NOTIFICATION_STATUS_KEY?.MY_WEBSITE_UPLOADED,
        };
        socket?.emit("website_uploaded_successfully", {
          socketId: apiConst.ADMIN_SOCKET_ID,
          fullName: `${profileData?.firstName} ${profileData?.lastName}`,
          websiteId: websiteDetails?.websiteId,
          websiteURL: websiteDetails?.websiteUrl,
          websiteName: websiteDetails?.title,
          sender: profileData?.profileId,
          status: NOTIFICATION_STATUS_KEY?.MY_WEBSITE_UPLOADED,
        });
        dispatch(addNotification(myWebsite));
        socket?.emit(NOTIFICATION_STATUS_KEY?.MY_WEBSITE_UPLOADED, myWebsite);
        setCurrent(0);
        setIsPrevious(false);
      }
      setIsStatus(false);
    }
  };
  useEffect(() => {
    const fontsArrayData = isEmpty(fontsArray) ? fontFamilyData : fontsArray;
    const sortByCategoryName = (a, b) =>
      a.categoryName > b.categoryName ? 1 : -1;
    const sortByStyleName = (a, b) => (a.styleName > b.styleName ? 1 : -1);
    const sortByFontName = (a, b) => (a.fontName > b.fontName ? 1 : -1);
    if (
      (Array.isArray(categoryData) && categoryData?.length > 0) ||
      (Array.isArray(stylesData) && stylesData?.length > 0) ||
      (Array.isArray(fontsArrayData) && fontsArrayData?.length > 0)
    ) {
      const categorySortedData = [...categoryData].sort(sortByCategoryName);
      const styleSortedData = [...stylesData].sort(sortByStyleName);
      const fontSortedData = [...fontsArrayData].sort(sortByFontName);

      setData((prevData) => ({
        ...prevData,
        categoryRecords: categorySortedData.map((obj) => ({
          ...obj,
          isSelected: false,
          isDisabled: false,
        })),
        styleRecords: styleSortedData.map((obj) => ({
          ...obj,
          isSelected: false,
          isDisabled: false,
        })),
        fontsRecord: fontSortedData.map((obj) => ({
          ...obj,
          isSelected: false,
          isDisabled: false,
        })),
      }));
    }
  }, [categoryData, stylesData, fontFamilyData, fontsArray]);
  return (
    <React.Fragment>
      <SubmitView
        {...{
          screenSize,
          submitWebsiteData,
          websiteError,
          categoryData: data?.categoryRecords,
          stylesData: data?.styleRecords,
          isSuccessModal,
          setIsSuccessModal,
          image,
          fontFamilyData: data?.fontsRecord,
          isStatus,
          submitWebImage,
          fontsArray,
          setSubmitWebsiteData,
          handleSubmitWebsiteData,
          handleSubmitWebsitePrevStep,
          current,
          handleGetWebScapDetails,
          setIsAlreadyExist,
          setData,
          data,
          setWebsiteError,
          setImage,
          setCurrent,
          isPrevious,
          setIsPrevious,
          isAlreadyExist,
          setImageSize,
          token,
          setIsAuthModal,
          profileData,
        }}
      />
      {isSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal,
            setIsSuccessModal,
            descriptionText: "Your Website Added successfully",
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SubmitContainer;
