"use client";
import {
  ButtonComponent,
  InputComponent,
  SuccessModal,
  CheckBoxComponent,
  LoadMoreComponent,
} from "@/src/components/CommonComponents";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Form, Modal, Row } from "antd";
import Image from "next/image";
import React from "react";
import "../../../../../styles/profile/profile.scss";
import {
  CHECKBOX_FIELD,
  USER_EDIT_WEBSITE_FIELD,
  USER_FILTER_DATA,
} from "@/src/const/nonPrimitive";
import { getUserWebsiteData } from "@/src/redux/actions";

const UserWebsitesView = ({
  image,
  deleteWebsite,
  websiteEditData,
  categoryData,
  stylesData,
  userEditData,
  handleUserWebsiteDelete,
  handleUserWebsiteEdit,
  handleUserEditCancel,
  handleUserWebsiteUpdate,
  handleEditUserWebsiteInputChange,
  handleUserWebFocus,
  websiteError,
  handleFilterUserWebsite,
  screenFilter,
  inputImageRef,
  editImage,
  handleDeleteWebsiteInputChange,
  handleCancelWebsite,
  handleSaveDeleteWebsite,
  handleImageFileChange,
  handleUploadImage,
  isStatus,
  isDeleteSuccessModal,
  setIsDeleteSuccessModal,
  isEditSuccessModal,
  setIsEditSuccessModal,
  handleCheckBoxChange,
  userWebsiteCardData,
  isSearch,
  token,
}) => {
  return (
    <div className={"main-website"}>
      <div className={"website-title"}>
        <h2>My Website</h2>
      </div>
      <div className={"website-menu"}>
        {USER_FILTER_DATA?.map((filterVal) => (
          <div key={filterVal}>
            <p
              className={`${"menu-name"} ${
                screenFilter === filterVal && "menu-overlay"
              }`}
              onClick={() => handleFilterUserWebsite(filterVal)}
            >
              {filterVal}
            </p>
          </div>
        ))}
      </div>

      <Row gutter={[30, 30]} className={"profile-website"}>
        <LoadMoreComponent
          {...{
            isCarousel: false,
            isMyWebsite: true,
            handleUserWebsiteEdit,
            handleUserWebsiteDelete,
            payload: {
              status: screenFilter,
            },
            token,
            isSearch,
            getWebsiteData: (payloadData) => getUserWebsiteData(payloadData),
            data: userWebsiteCardData,
          }}
        />
      </Row>
      <Modal
        open={userEditData?.isModalEdit}
        footer={false}
        onCancel={handleUserEditCancel}
        closeIcon={false}
        className={"card-edit-details-main"}
        width={1000}
      >
        <Row className={"card-edit-details"}>
          <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form className={"card-edit-form"}>
              {USER_EDIT_WEBSITE_FIELD?.map((editData) => {
                return (
                  <div key={editData?.label} className={"card-edit-form-wrap"}>
                    <Form.Item className={"card-edit-form-item"}>
                      <div className={"card-edit-form-label"}>
                        <label>{editData?.label}</label>
                      </div>
                      <InputComponent
                        type={editData?.type}
                        placeholder={editData?.placeholder}
                        maxLength={editData?.maxLength - 1}
                        name={editData?.name}
                        isCount={true}
                        error={websiteError?.[editData?.name]}
                        value={websiteEditData?.[editData?.name]}
                        handleChange={(e) =>
                          handleEditUserWebsiteInputChange(
                            e,
                            0,
                            editData?.maxLength
                          )
                        }
                        handleBlur={(e) =>
                          handleUserWebFocus(
                            e,
                            editData?.name,
                            editData?.maxLength - 1
                          )
                        }
                        classNames={"card-edit-input"}
                      />
                    </Form.Item>
                  </div>
                );
              })}
              {CHECKBOX_FIELD?.map((editCheckBox) => {
                const options =
                  editCheckBox?.name?.toLowerCase() === "category".toLowerCase()
                    ? categoryData || []
                    : stylesData || [];
                return (
                  <Form.Item
                    key={editCheckBox?.number}
                    className={"industry-styles-wrap"}
                  >
                    <div className={"industry-styles-main"}>
                      <label className={"industry-styles-title"}>
                        {editCheckBox?.label?.replace("*", "")}
                      </label>
                    </div>
                    <div className={"list-main"}>
                      {options?.map((ele) => {
                        let a =
                          editCheckBox?.name?.toLowerCase() ===
                          "category".toLowerCase()
                            ? ele?.categoryName
                            : ele?.styleName;
                        return (
                          <div
                            className={"industry-styles-checkbox-main"}
                            key={a}
                          >
                            <CheckBoxComponent
                              {...{
                                obj: ele,
                                value: a,
                                name: editCheckBox?.name,
                                submitWebsiteData: websiteEditData,
                                handleCheckBoxChange,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <p className={"profile-website-error-message"}>
                      {websiteError?.[editCheckBox?.name]}
                    </p>
                  </Form.Item>
                );
              })}
            </Form>
          </Col>
          <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <div className={"card-edit-image-main"}>
              <div className={"card-edit-image-wrap"}>
                <React.Fragment>
                  <div className={"container"}>
                    <Image
                      src={image ? URL.createObjectURL(image) : editImage}
                      alt=""
                      fill
                      className={"card-image"}
                    />
                    <div className={"middle"} onClick={handleUploadImage}>
                      <p className={"edit-website-image-text"}>
                        you want to upload image your self ?
                      </p>
                      <UploadOutlined className={"upload-profile-image"} />
                      <input
                        type="file"
                        onChange={handleImageFileChange}
                        ref={inputImageRef}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                  <p className={"image-error"}>{websiteError?.image}</p>
                </React.Fragment>
              </div>
            </div>
          </Col>
        </Row>
        <div className={"card-edit-btn"}>
          <ButtonComponent
            btnText={"Cancel"}
            classNames={"card-edit-cancel"}
            handleClick={handleUserEditCancel}
          />
          <ButtonComponent
            btnText={isStatus ? <LoadingOutlined /> : "Save"}
            handleClick={handleUserWebsiteUpdate}
            classNames={"card-edit-save"}
          />
        </div>
      </Modal>
      <Modal
        open={deleteWebsite?.isModelOpen}
        footer={false}
        closeIcon={false}
        onCancel={handleCancelWebsite}
        className={"delete-website-modal-main"}
      >
        <p className={"sure-delete-text"}>
          Are you sure want to delete this website?
        </p>
        <p className={"url-text"}>
          Enter Your URL
          <span className={"website-url"}>"{deleteWebsite?.urlName}"</span>
        </p>
        <InputComponent
          type="text"
          value={deleteWebsite?.inputValue}
          handleChange={handleDeleteWebsiteInputChange}
          placeholder="enter website URL"
          classNames={"website-delete-input"}
        />
        <div className={"save-btn-main"}>
          <ButtonComponent
            btnText={"Cancel"}
            classNames={"cancel-button"}
            handleClick={handleCancelWebsite}
          />
          <ButtonComponent
            btnText={"Delete"}
            disabled={!deleteWebsite?.isConfirm}
            handleClick={() => handleSaveDeleteWebsite()}
            classNames={"delete-button"}
          />
        </div>
      </Modal>
      {isDeleteSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal: isDeleteSuccessModal,
            setIsSuccessModal: setIsDeleteSuccessModal,
            descriptionText: "Your Website Delete Successfully",
          }}
        />
      )}
      {isEditSuccessModal && (
        <SuccessModal
          {...{
            isSuccessModal: isEditSuccessModal,
            setIsSuccessModal: setIsEditSuccessModal,
            descriptionText: "Your Website Update successfully",
          }}
        />
      )}
    </div>
  );
};

export default UserWebsitesView;
