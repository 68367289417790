"use client";
import React, { useRef, useState } from "react";
import PasswordEmailView from "./PasswordEmailView";
import { SPACE_REGEX } from "@/src/const/primitive";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { isEmpty } from "@/src/utils";
import { sendPasswordEmail } from "@/src/redux/actions";
import { useDispatch } from "react-redux";

const PasswordEmailContainer = () => {
  const [isEmailSendModal, setIsEmailSendModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({ email: "" });
  const [forgotPasswordError, setForgotPasswordError] = useState({});
  const [isStatus, setIsStatus] = useState(false);
  const inputRef = useRef();
  const dispatch = useDispatch();

  const handlePasswordChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    value = value.trimStart();
    value = value?.replace(SPACE_REGEX, "");
    const passwordDetails = {
      ...forgotPassword,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX),
    };
    value?.length < LAST_INDEX && setForgotPassword(passwordDetails);
  };
  const handlePasswordFocus = (maxLength, name) => {
    const { errors } = addWebsiteValidation(
      name,
      forgotPassword,
      forgotPasswordError,
      true
    );
    setForgotPasswordError(errors);
    if (forgotPassword[name]?.length === maxLength) {
      setForgotPasswordError({
        ...forgotPasswordError,
        [name]: "",
      });
    }
  };
  const handleSubmitPasswordEmail = async (e) => {
    let inputName = e?.target?.name;
    let names;
    if (!isEmpty(inputRef.current)) {
      if (
        isEmpty(forgotPassword?.email) ||
        !isEmpty(forgotPasswordError?.email)
      ) {
        inputRef.current.focus();
      }
    } else {
      let isValidArr = Object.keys(forgotPassword);
      isValidArr?.forEach((val) => {
        names = val;
      });
      const { errors, isValid } = addWebsiteValidation(
        inputName ? inputName : names,
        forgotPassword,
        forgotPasswordError,
        ""
      );
      setForgotPasswordError(errors);
      if (isValid === false) {
        if (!isEmpty(forgotPassword?.email)) {
          setIsStatus(true);
          const response = await dispatch(
            sendPasswordEmail(forgotPassword?.email)
          );
          setIsStatus(false);
          if (response?.status === 200) {
            setIsEmailSendModal(true);
            setForgotPassword({
              email: "",
            });
          } else if (response?.status === 404) {
            setForgotPassword({
              email: "Enter Valid Email",
            });
          }
        }
      }
    }
  };
  const handleForgotEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitPasswordEmail(e);
    }
  };
  return (
    <PasswordEmailView
      {...{
        inputRef,
        forgotPasswordError,
        forgotPassword,
        handleSubmitPasswordEmail,
        handlePasswordChange,
        handleForgotEmailKeyDown,
        handlePasswordFocus,
        isStatus,
        isEmailSendModal,
        setIsEmailSendModal,
      }}
    />
  );
};

export default PasswordEmailContainer;
