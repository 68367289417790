"use client";
import React, { useRef, useState } from "react";
import WebSnapShotView from "./WebSnapShotView";
import { useDispatch, useSelector } from "react-redux";
import { getSubmitWebsiteScreenShotData } from "@/src/redux/actions";
import { isEmpty } from "@/src/utils";
import { SPACE_REGEX } from "@/src/const/primitive";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { getUserLatestData } from "@/src/redux/actions/getApis/GetApis";
import { setIsUserNameApplied } from "@/src/redux/reducers/slices";

const WebSnapShotContainer = ({ token }) => {
  const { setIsScreenShotOpen, isScreenShotOpen } = useNav();
  const { topWebsiteData } = useSelector((state) => state.topWebsites);
  const [isGetScreenshotDetails, setIsGetScreenshotDetails] = useState(false);
  const [getScreenShotData, setGetScreenShotData] = useState([]);
  const [screenShotData, setScreenShotData] = useState({ ssUrl: "" });
  const [websiteError, setWebsiteError] = useState({});
  const placement = "right";
  const [drawer, setDrawer] = useState({
    isLink: false,
    isOpen: false,
    isModalOpen: false,
    isLoading: false,
    isDetailsPage: false,
    data: {},
  });
  const [modal, setModal] = useState({
    isAddCollection: false,
    isCreateCollection: false,
    collectionName: "",
    id: "",
    image: "",
    imgAlt: "",
  });
  const dispatch = useDispatch();
  const [isPortfolioWebsite, setIsPortfolioWebsite] = useState(true);
  const [userOnHoverFilterData, setUserOnHoverFilterData] = useState([]);
  const { userNameApplied } = useSelector((state) => state.website);
  const timeoutRef = useRef(null);
  const handleOnMouseHover = async (id, websiteId) => {
    if (userNameApplied !== websiteId || isEmpty(userOnHoverFilterData)) {
      setIsPortfolioWebsite(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        const response = await getUserLatestData(id);
        setUserOnHoverFilterData(response);
        dispatch(setIsUserNameApplied(websiteId));
        setIsPortfolioWebsite(false);
      }, 500);
    }
  };
  const handleScreenShotOpenDrawer = () => {
    setIsScreenShotOpen(true);
  };
  const handleScreenShotOnClose = () => {
    setIsScreenShotOpen(false);
    setWebsiteError({});
    setIsGetScreenshotDetails(false);
    setGetScreenShotData([]);
  };
  const handleScreenShotDrawerChange = (e) => {
    let { value } = e.target;
    value = value?.replace(SPACE_REGEX, "");
    setScreenShotData({
      ...screenShotData,
      ssUrl: value,
    });
    if (isEmpty(value)) {
      setGetScreenShotData([]);
    }
  };
  const handleFocus = (e, name) => {
    const { errors } = addWebsiteValidation(
      name,
      screenShotData,
      websiteError,
      true
    );
    setWebsiteError(errors);
  };

  const handleGetScreenShotDetails = async () => {
    const { errors } = addWebsiteValidation(
      "ssUrl",
      screenShotData,
      websiteError,
      true
    );
    setWebsiteError(errors);
    if (Object.values(errors).every((value) => !value)) {
      setIsGetScreenshotDetails(true);
      const response = await dispatch(
        getSubmitWebsiteScreenShotData(screenShotData?.ssUrl)
      );
      setIsGetScreenshotDetails(false);
      setGetScreenShotData(response?.data);
    }
  };

  return (
    <WebSnapShotView
      {...{
        handleScreenShotOpenDrawer,
        handleScreenShotDrawerChange,
        handleGetScreenShotDetails,
        handleScreenShotOnClose,
        isGetScreenshotDetails,
        getScreenShotData,
        isScreenShotOpen,
        topWebsiteData,
        screenShotData,
        placement,
        token,
        handleFocus,
        websiteError,
        handleOnMouseHover,
        isPortfolioWebsite,
        userOnHoverFilterData,
        drawer,
        setDrawer,
        modal,
        setModal,
      }}
    />
  );
};

export default WebSnapShotContainer;
