"use client";
import Image from "next/image";
import React from "react";
import "../../../../../styles/about/about.scss";
import { aboutUs, aboutUsAnimation } from "../../../../../public/assets";
import { BackButtonComponent } from "@/src/components/CommonComponents";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const AboutView = () => {
  return (
    <div className={"about-page-mein"}>
      <BackButtonComponent url="/" />
      <div className={"about-page-wrap"}>
        <div className={"about-header-main"}>
          <div>
            <h1 className={"about-us-title"}>About Us</h1>
          </div>
          <div className={"about-details-main"}>
            <p className={"about-details"}>
              The global hub of design innovation awaits
            </p>
          </div>
          <p className={"about-us-description"}>
            We're dedicated to forgoing the ultimate space for creatives to
            exchange, thrive and secure opportunities.
          </p>
        </div>
        <div className="about-content-main row">
          <div className="padding-right col-xxl-12 col-lg-24 ">
            <div className={"about-us-image-main"}>
              <Image
                src={aboutUs}
                alt="about_us"
                height={"100%"}
                width={"100%"}
                className={"about-us-image"}
              />
            </div>
          </div>
          <div className="padding-left col-xxl-12 col-lg-24 ">
            <div className={"about-us-text-main"}>
              <p className={"about-us-text"}>
                Welcome to our inspiration hub! Dive into a world where
                creativity flourishes your passion. What's currently occupying
                your time? Numerous individuals seek design inspiration and
                feedback on UxVortex. We empower creators like yourself to
                present concise screenshots to exhibit your ongoing projects,
                elevate your portfolio, and relish your creative
                endeavors—irrespective of your professional specialization.
                UXVortex serves as a seamless bridge connecting clients and
                agencies, simplifying the website selection process for clients
                and providing a platform for agencies and creators to showcase
                their work through easy posting and visibility on our website.
              </p>
              <p className={"about-us-text"}>
                Established in 2023, we are a self-sustained and prosperous
                enterprise facilitating design talent in sharing, expanding, and
                securing opportunities with tens of thousands of today's most
                groundbreaking brands globally.
              </p>
            </div>
          </div>
        </div>
        <div className="about-content-main about-second-content row">
          <div className="padding-right col-xxl-12 col-lg-24 ">
            <div className={"about-us-text-main"}>
              <p className={"about-us-text"}>
                At Uxvortex, we believe in the transformative power of design to
                elevate experiences and spark inspiration. Our platform serves
                as a melting pot for diverse talents, fostering a community
                where designers, artists, and innovators come together to
                express, collaborate, and push the boundaries of creativity.
              </p>
              <p className={"about-us-text"}>
                Founded with a passion for exceptional design, we aim to be a
                catalyst for your creative journey. Whether you're a seasoned
                professional or an aspiring talent, Uxvortex is your canvas to
                showcase, share, and explore the incredible world of design.
                Dive into a universe of artistic expression, connect with
                like-minded individuals, and let your imagination run wild.
              </p>
            </div>
          </div>
          <div className="padding-left col-xxl-12 col-lg-24 ">
            <div className={"about-us-image-main"}>
              <Lottie
                loop
                animationData={aboutUsAnimation}
                play
                className={"about-us-image"}
              />
            </div>
          </div>
        </div>
        <p className={"about-us-text"}>
          Our commitment is to provide a dynamic platform that celebrates the
          unique voices within the design community. Join us in shaping the
          future of design or concept at a time. Let's create, inspire, and
          transform the world together. Welcome to Uxvortex—where creativity
          knows no bounds.
        </p>
      </div>
    </div>
  );
};

export default AboutView;
