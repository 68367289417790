"use client";
import React, { useEffect, useState } from "react";
import SearchView from "./SearchView";
import Cookies from "js-cookie";
import { isEmpty } from "@/src/utils";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteScreenShot } from "@/src/redux/actions";
import {
  isAllWebsiteEndPage,
  isLoadingWebsite,
  websiteSearchData,
} from "@/src/redux/reducers/slices";
import { TWELVE_COUNT } from "@/src/const/primitive";

const SearchContainer = ({ token }) => {
  const { setIsSearch, filterValueJson, setFilterValueJson, filterRef } =
    useNav();
  const { profileData } = useSelector((state) => state.profile);
  const [categorySearch, setCategorySearch] = useState({
    previousSearch: [],
    searchKey: "",
  });

  const [searchCookies, setSearchCookies] = useState([]);
  const dispatch = useDispatch();
  const storedSearchHistory = Cookies.get("cookies");
  const handleRemoveCookies = (cookie, e) => {
    e.stopPropagation();
    let allSearchKey;
    if (storedSearchHistory) {
      allSearchKey = JSON.parse(storedSearchHistory);
    } else {
      allSearchKey = [];
    }
    allSearchKey = allSearchKey?.filter((obj) => obj !== cookie);
    // allSearchKey = [...allSearchKey, categorySearch?.searchKey];
    allSearchKey = new Set(allSearchKey);
    allSearchKey = Array.from(allSearchKey);
    Cookies.set("cookies", JSON.stringify(allSearchKey), {
      expires: 30,
    });
    setSearchCookies(allSearchKey);
  };
  const handleSearchValue = () => {
    setCategorySearch({
      ...categorySearch,
      searchKey: "",
    });
    setIsSearch(false);
  };
  const handleCategorySearchKeyDown = async (e, title) => {
    if (e.key === "Enter") {
      let newTitle = title?.toLowerCase();
      setIsSearch(true);
      dispatch(isLoadingWebsite(true));
      if (typeof window !== "undefined") {
        window?.scrollTo({
          top: filterRef.current.offsetTop,
          behavior: "smooth",
        });
      }
      if (token) {
        let allSearchKey;

        if (storedSearchHistory) {
          allSearchKey = JSON.parse(storedSearchHistory);
        } else {
          allSearchKey = [];
        }
        if (!isEmpty(categorySearch?.searchKey)) {
          allSearchKey = allSearchKey?.filter(
            (key) =>
              key?.toLowerCase() !== categorySearch?.searchKey.toLowerCase()
          );
          allSearchKey = [categorySearch?.searchKey, ...allSearchKey]?.slice(
            0,
            5
          );

          Cookies.set("cookies", JSON.stringify(allSearchKey), {
            expires: 30,
          });
          setSearchCookies(allSearchKey);
        }
      }
      let sendFilterData = {
        ...filterValueJson,
        [newTitle]: categorySearch?.searchKey?.toLowerCase(),
      };
      let EmptySearchData = [];
      const payloadData = {
        page: 1,
        limit: TWELVE_COUNT,
        ...filterValueJson,
        search: categorySearch?.searchKey?.toLowerCase(),
      };
      setFilterValueJson(sendFilterData);
      const data = await getWebsiteScreenShot(payloadData);
      let SearchData = [...EmptySearchData, data?.data];
      await dispatch(websiteSearchData(SearchData));
      dispatch(isAllWebsiteEndPage(data?.isEnd));
      await dispatch(isLoadingWebsite(false));
    }
  };
  const handleFilterChange = async (e) => {
    const { value } = e.target;
    if (isEmpty(value)) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    setCategorySearch({
      ...categorySearch,
      searchKey: value,
    });
  };
  useEffect(() => {
    if (!isEmpty(token) && !isEmpty(storedSearchHistory)) {
      setSearchCookies(JSON.parse(storedSearchHistory));
    } else {
      setSearchCookies([]);
    }
  }, [token]);

  const handleAddSearch = async (title) => {
    setCategorySearch({
      ...categorySearch,
      searchKey: title,
    });
    let newTitle = title?.toLowerCase();
    setIsSearch(true);
    dispatch(isLoadingWebsite(true));
    if (typeof window !== "undefined") {
      window?.scrollTo({
        top: filterRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    // if (token) {
    //   let allSearchKey;

    //   if (storedSearchHistory) {
    //     allSearchKey = JSON.parse(storedSearchHistory);
    //   } else {
    //     allSearchKey = [];
    //   }
    //   if (!isEmpty(categorySearch?.searchKey)) {
    //     allSearchKey = allSearchKey?.filter(
    //       (key) =>
    //         key?.toLowerCase() !== categorySearch?.searchKey.toLowerCase()
    //     );
    //     allSearchKey = [categorySearch?.searchKey, ...allSearchKey]?.slice(
    //       0,
    //       5
    //     );

    //     Cookies.set("cookies", JSON.stringify(allSearchKey), {
    //       expires: 30,
    //     });
    //     setSearchCookies(allSearchKey);
    //   }
    // }
    let sendFilterData = {
      ...filterValueJson,
      [newTitle]: categorySearch?.searchKey?.toLowerCase(),
    };
    let EmptySearchData = [];
    const payloadData = {
      page: 1,
      limit: TWELVE_COUNT,
      ...filterValueJson,
      search: categorySearch?.searchKey?.toLowerCase(),
    };
    setFilterValueJson(sendFilterData);
    const data = await getWebsiteScreenShot(payloadData);
    let SearchData = [...EmptySearchData, data?.data];
    await dispatch(websiteSearchData(SearchData));
    dispatch(isAllWebsiteEndPage(data?.isEnd));
    await dispatch(isLoadingWebsite(false));
  };

  return (
    <SearchView
      {...{
        handleRemoveCookies,
        handleSearchValue,
        handleCategorySearchKeyDown,
        searchCookies,
        categorySearch,
        handleFilterChange,
        profileData,
        handleAddSearch,
      }}
    />
  );
};

export default SearchContainer;
