"use client";
import React from "react";
import "../../../../../../styles/websiteCard/websiteCard.scss";
import { Row, Skeleton } from "antd";
import {
  DropDownComponent,
  LoadMoreComponent,
} from "@/src/components/CommonComponents";
import { getWebsiteScreenShot } from "@/src/redux/actions";
import { HOME_FILTER } from "@/src/const/nonPrimitive";

const AllWebsiteView = (props) => {
  const {
    allWebsites,
    token,
    filterValueJson,
    filterRef,
    orderByFilter,
    isSearch,
    handleFilterWebsiteData,
    isLoadingWebsiteState,
    setLoadingWebsite,
    isDropDownLoad,
  } = props;
  return (
    <React.Fragment>
      <div className={"dashboard-card-wrap"} ref={filterRef}>
        <div className={"paid-card-heading-main"}>
          <p className={"showing-website-heading"}>
            Showcasing the best agency websites on the web
          </p>
          {isDropDownLoad ? (
            <Skeleton.Input active />
          ) : (
            <DropDownComponent
              options={HOME_FILTER}
              name="orderBy"
              defaultValue={"Newest"}
              value={orderByFilter}
              allowClear={orderByFilter !== "Newest" && true}
              handleChange={handleFilterWebsiteData}
            />
          )}
        </div>
        <Row gutter={[40, 40]} className={"screenshot-paid-card-wrap"}>
          <LoadMoreComponent
            {...{
              isCarousel: false,
              token,
              payload: {
                category: filterValueJson?.category,
                style: filterValueJson?.style,
                font: filterValueJson?.font,
                color: filterValueJson?.color,
                country: filterValueJson?.country,
                search: filterValueJson?.search,
                orderBy: filterValueJson?.orderBy,
              },
              getWebsiteData: (payloadData) =>
                getWebsiteScreenShot(payloadData),
              isSearch,
              setLoadingWebsite,
              isLoadingWebsiteState,
              data: allWebsites,
            }}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AllWebsiteView;
