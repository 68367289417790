"use client";
import { forgotPasswordImage, mail } from "@/public/assets";
// import { Col, Row } from "antd";
import React from "react";
import {
  BackButtonComponent,
  ButtonComponent,
  ImageComponent,
  InputComponent,
  SuccessModal,
} from "../../CommonComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty } from "@/src/utils";
import "../../../../styles/password/forgotpassword.scss";
const MyLottieAnimation = React.lazy(
  () => import("../../CommonComponents/Lottie/LottieComponent")
);

const PasswordEmailView = ({
  inputRef,
  forgotPasswordError,
  forgotPassword,
  handleSubmitPasswordEmail,
  handlePasswordChange,
  handleForgotEmailKeyDown,
  handlePasswordFocus,
  isStatus,
  isEmailSendModal,
  setIsEmailSendModal,
}) => {
  return (
    <div className="forgot_password_wrap">
      <div className="row">
        <div className={"col-xxl-12 col-md-24 forgot_password_left"}>
          <React.Suspense fallback={"loading"}>
            <MyLottieAnimation
              lottieImageData={forgotPasswordImage}
              lottieClassName={"forgot_password_image"}
            />
          </React.Suspense>
        </div>
        <div className="col-xxl-12 col-md-24 forgot_password_right common">
          <BackButtonComponent url="/" classNames="forgot_back_btn" />
          <div className={"forgot_password_main"}>
            <div className={"forgot_password_title"}>Forgot Password?</div>
            <div className={"forgot_password_description"}>
              Enter your email address and we will send you a link to add your
              new password.
            </div>
            <div className={"forgot_reset_form"}>
              <div className={"forgot_password_form"}>
                <div className={"border"}>
                  <ImageComponent
                    imageSrc={mail}
                    imageAlt="mail"
                    imageClassName={"email_image"}
                  />
                </div>
                <label className={"email_label"}>{"Email"}</label>
                <InputComponent
                  classNames={"forgot_password_email"}
                  type={"email"}
                  name={"email"}
                  inputRef={
                    isEmpty(forgotPassword?.email) ||
                    !isEmpty(forgotPasswordError?.email)
                      ? inputRef
                      : null
                  }
                  handleChange={(e) => handlePasswordChange(e, 0, 500)}
                  handleBlur={() => handlePasswordFocus(500, "email")}
                  value={forgotPassword?.email}
                  handleKeyDown={handleForgotEmailKeyDown}
                  placeholder={"Enter Your Email"}
                  error={forgotPasswordError?.email}
                />
              </div>

              <ButtonComponent
                btnText={isStatus ? <LoadingOutlined /> : "Confirm "}
                handleClick={isStatus ? () => {} : handleSubmitPasswordEmail}
                classNames={"forgot_confirm"}
              />
            </div>
          </div>
        </div>
      </div>
      {isEmailSendModal && (
        <SuccessModal
          {...{
            isSuccessModal: isEmailSendModal,
            setIsSuccessModal: setIsEmailSendModal,
            descriptionText: "You should receive an email",
            isEmailSend: isEmailSendModal,
          }}
        />
      )}
    </div>
  );
};

export default PasswordEmailView;
