import Image from "next/image";
import React from "react";
import { Col, Row } from "antd";
import { isEmpty } from "@/src/utils";
import "../../../../styles/filterNavbar/filterNavbarResponsive.scss";
import Link from "next/link";
import { MenuOutlined } from "@ant-design/icons";
import { closes, leftArrow, whiteLogo } from "@/public/assets";
import { InputComponent } from "../../CommonComponents";
import { SwatchesPicker } from "react-color";
// import { SwatchesPicker } from "react-color";

const FilterResponsiveNavMenu = (props) => {
  const {
    filterDropDownName,
    handleClickCollapse,
    componentRef,
    isResponsive,
    filterValueJson,
    open,
    data,
    defaultClose,
    handleFilterData,
    handleDropDownSearchValue,
    dropdownSearchValue,
    handleCloseFilterData,
    getRegionNames,
    handleScreenShotOpenDrawer,
    handleClickDisplayData,
    handleBackData,
  } = props;
  return (
    <div className={"responsive_filter_main"} ref={componentRef}>
      <div
        className={`${"responsive_filter_wrap"} ${
          isResponsive?.isOpenMenu ? "responsive_filter_open" : ""
        }`}
      >
        <div
          className={`${"responsive_details_main"} ${
            open ? "responsive_details_main_open" : ""
          }`}
        >
          <div
            className={`${"menu_list_mein"} ${
              isResponsive?.isOpenMenu
                ? "list_main_open"
                : !defaultClose
                  ? "list_main_default"
                  : "list_main_close"
            }`}
          >
            {isResponsive?.isOpenMenu && (
              <React.Fragment>
                <div className={"item_title_main"}>
                  {/* <Image src={popularIcon} alt="popular-icon" /> */}
                  <div className={"item_icon_wrap"}>
                    <div className={"item_icon"}></div>
                    <p>Popular</p>
                  </div>
                  <div
                    className={`${"item_sub_title"} ${
                      filterDropDownName?.toLowerCase() === "category"
                        ? "selected_title"
                        : ""
                    }`}
                    onClick={() =>
                      handleClickDisplayData("popular", "Category")
                    }
                  >
                    {filterDropDownName?.toLowerCase() === "category" && (
                      <div className={"item_sub_title_line"}></div>
                    )}
                    <p>Category</p>
                  </div>
                  <div
                    className={`${"item_sub_title"} ${
                      filterDropDownName?.toLowerCase() === "style"
                        ? "selected_title"
                        : ""
                    }`}
                    onClick={() => handleClickDisplayData("popular", "Style")}
                  >
                    {filterDropDownName?.toLowerCase() === "style" && (
                      <div className={"item_sub_title_line"}></div>
                    )}
                    <p>Style</p>
                  </div>
                </div>
                <div className={"item_title_main"}>
                  {/* <Image src={typographyIocn} alt="typography-icon" /> */}
                  <div className={"item_icon_wrap"}>
                    <div className={"item_icon"}></div>
                    <p>Typography</p>
                  </div>
                  <div
                    className={`${"item_sub_title"} ${
                      filterDropDownName?.toLowerCase() === "font"
                        ? "selected_title"
                        : ""
                    }`}
                    onClick={() => handleClickDisplayData("typography", "Font")}
                  >
                    {filterDropDownName?.toLowerCase() === "font" && (
                      <div className={"item_sub_title_line"}></div>
                    )}
                    <p>Fonts</p>
                  </div>
                  <div
                    className={`${"item_sub_title"} ${
                      filterDropDownName?.toLowerCase() === "color"
                        ? "selected_title"
                        : ""
                    }`}
                    onClick={() =>
                      handleClickDisplayData("typography", "Color")
                    }
                  >
                    {filterDropDownName?.toLowerCase() === "color" && (
                      <div className={"item_sub_title_line"}></div>
                    )}
                    <p>Colors</p>
                  </div>
                </div>
                <div
                  // onClick={() => showDrawer("country")}
                  onClick={() => handleClickDisplayData("country", "Country")}
                  className={"item_title_main"}
                >
                  <div
                    className={`${"item_title"} ${
                      filterDropDownName?.toLowerCase() === "country"
                        ? "selected_title_country"
                        : ""
                    }`}
                  >
                    <div className={"item_icon"}></div>
                    <p>Country</p>
                  </div>
                </div>
                <div className={"item_title_main"}>
                  <Link href={"/submit"} className={"item_title_link"}>
                    {/* <Image
                      src={submitWebsite}
                      height={22}
                      width={22}
                      alt="submitWebsite"
                    /> */}
                    <div className={"item_icon"}></div>
                    <p> Submit Website</p>
                  </Link>
                </div>
                <div className={"item_title_main"}>
                  <Link href={"/about"} className={"item_title_link"}>
                    {/* <Image
                      src={aboutUsIcon}
                      height={25}
                      width={25}
                      alt="about us"
                    /> */}
                    <div className={"item_icon"}></div>
                    <p>About Us</p>
                  </Link>
                </div>

                <div
                  onClick={handleScreenShotOpenDrawer}
                  className={"item_title_main"}
                >
                  <div
                    className={`${"item_title"} ${"item_title_web_snapshot"} ${
                      filterDropDownName === "webSnapshot"
                        ? "selected_title_country"
                        : ""
                    }`}
                  >
                    <div className={"item_icon"}></div>
                    <p>Web Snapshot</p>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div
            className={`${"filter_menu_item_main"} ${
              open
                ? "filter_menu_item_main_open"
                : "filter_menu_item_main_close"
            }`}
          >
            {open && (
              <React.Fragment>
                <div className={"filter_icon_title_wrap"}>
                  <div>
                    <Image
                      src={leftArrow}
                      alt="left-arrow"
                      className={"filter_menu_back_icon"}
                      onClick={handleBackData}
                    />
                  </div>
                  <div className={"filer_menu_item"}>
                    {data?.map((obj) => (
                      <div key={obj?.title}>
                        <h1
                          className={`${"item_title"} ${
                            obj?.title ? "item_title_border_bottom" : ""
                          }`}
                        >
                          {obj?.title}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={"filter_input_main"}>
                  <InputComponent
                    value={dropdownSearchValue}
                    type="search"
                    placeholder="search..."
                    handleChange={(e) => handleDropDownSearchValue(e)}
                    classNames={"filter_input"}
                  />
                </div>
                <div className={"filer_menu_item_wrap"}>
                  {data?.map((obj) => (
                    <div key={obj?.title}>
                      {isEmpty(obj?.data) &&
                      obj?.data?.length <= 0 &&
                      obj?.title !== "Color" ? (
                        <p className={"no_data_found"}>No Data Found</p>
                      ) : obj?.title === "Color" ? (
                        <React.Fragment>
                          <SwatchesPicker
                            className={"color_picker_main"}
                            onChange={(e) =>
                              handleFilterChange(e?.hex, obj?.title)
                            }
                          />
                        </React.Fragment>
                      ) : (
                        <Row
                          gutter={[15, 15]}
                          className={"filter_nav_item_main"}
                        >
                          {obj?.data?.map((ele) => {
                            const title = obj?.title?.toLowerCase();
                            return (
                              <Col
                                key={ele?.name}
                                span={24}
                                xxl={8}
                                xl={8}
                                lg={8}
                                md={8}
                                sm={8}
                                xs={12}
                                className={"filter_nav_item_main"}
                              >
                                <div
                                  className={`${
                                    filterValueJson[title] === ele.name
                                      ? "filter_nav_item_selected"
                                      : ""
                                  } ${"filter_nav_item"}`}
                                >
                                  <p
                                    className={"options_lists"}
                                    onClick={() =>
                                      handleFilterData(obj?.title, ele?.name)
                                    }
                                  >
                                    {obj?.title === "Country"
                                      ? getRegionNames.of(ele?.name)
                                      : ele?.name}
                                  </p>
                                  {filterValueJson[title] === ele.name && (
                                    <Image
                                      src={closes}
                                      alt="close"
                                      onClick={() =>
                                        handleCloseFilterData(obj?.title)
                                      }
                                      className={"filter_nav_icon"}
                                    />
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      )}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className={`${"responsive_logo_main"} ${
            !isResponsive?.isOpenMenu ? "responsive_logo_main_close" : ""
          }`}
        >
          <div>
            <Image src={whiteLogo} alt="filter-logo" width={78} height={15} />
          </div>
          <div className={"filter_menu_icon"}>
            {isResponsive?.isOpenMenu ? (
              <Image
                src={closes}
                alt="close"
                onClick={handleClickCollapse}
                height={10}
                width={10}
              />
            ) : (
              <MenuOutlined
                onClick={handleClickCollapse}
                className={"menu_toggle_icon"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterResponsiveNavMenu;
