export const COUNTRY_LIST_PHONE_CODE = [
  {
    name: "US",
    code: "+1",
    maxLength: 10,
  },
  {
    name: "AF",
    code: "+93",
    maxLength: 13,
  },
  {
    name: "AL",
    code: "+355",
    maxLength: 12,
  },
  {
    name: "DZ",
    code: "+213",
    maxLength: 12,
  },
  {
    name: "AS",
    code: "+1684",
    maxLength: 12,
  },
  {
    name: "AD",
    code: "+376",
    maxLength: 9,
  },
  {
    name: "AO",
    code: "+244",
    maxLength: 9,
  },
  {
    name: "AI",
    code: "+1264",
    maxLength: 12,
  },
  {
    name: "AG",
    code: "+1268",
    maxLength: 11,
  },
  {
    name: "AR",
    code: "+54",
    maxLength: 13,
  },
  {
    name: "AM",
    code: "+374",
    maxLength: 12,
  },
  {
    name: "AW",
    code: "+297",
    maxLength: 10,
  },
  {
    name: "AU",
    code: "+61",
    maxLength: 12,
  },
  {
    name: "AT",
    code: "+43",
    maxLength: 12,
  },
  {
    name: "AZ",
    code: "+994",
    maxLength: 12,
  },
  {
    name: "BS",
    code: "+1242",
    maxLength: 11,
  },
  {
    name: "BH",
    code: "+973",
    maxLength: 8,
  },
  {
    name: "BD",
    code: "+880",
    maxLength: 12,
  },
  {
    name: "BB",
    code: "+1246",
    maxLength: 11,
  },
  {
    name: "BY",
    code: "+375",
    maxLength: 12,
  },
  {
    name: "BE",
    code: "+32",
    maxLength: 11,
  },
  {
    name: "BZ",
    code: "+501",
    maxLength: 9,
  },
  {
    name: "BJ",
    code: "+229",
    maxLength: 10,
  },
  {
    name: "BM",
    code: "+1441",
    maxLength: 11,
  },
  {
    name: "BT",
    code: "+975",
    maxLength: 10,
  },
  {
    name: "BO",
    code: "+591",
    maxLength: 10,
  },
  {
    name: "BA",
    code: "+387",
    maxLength: 10,
  },
  {
    name: "BW",
    code: "+267",
    maxLength: 10,
  },
  {
    name: "BR",
    code: "+55",
    maxLength: 11,
  },
  {
    name: "IO",
    code: "+246",
    maxLength: 11,
  },
  {
    name: "BG",
    code: "+359",
    maxLength: 11,
  },
  {
    name: "BF",
    code: "+226",
    maxLength: 8,
  },
  {
    name: "BI",
    code: "+257",
    maxLength: 9,
  },
  {
    name: "KH",
    code: "+855",
    maxLength: 9,
  },
  {
    name: "CM",
    code: "+237",
    maxLength: 9,
  },
  {
    name: "CA",
    code: "+1",
    maxLength: 10,
  },
  {
    name: "CV",
    code: "+238",
    maxLength: 9,
  },
  {
    name: "KY",
    code: "+1345",
    maxLength: 11,
  },
  {
    name: "CF",
    code: "+236",
    maxLength: 8,
  },
  {
    name: "TD",
    code: "+235",
    maxLength: 8,
  },
  {
    name: "CL",
    code: "+56",
    maxLength: 12,
  },
  {
    name: "CN",
    code: "+86",
    maxLength: 11,
  },
  {
    name: "CO",
    code: "+57",
    maxLength: 10,
  },
  {
    name: "KM",
    code: "+269",
    maxLength: 7,
  },
  {
    name: "CG",
    code: "+242",
    maxLength: 8,
  },
  {
    name: "CD",
    code: "+243",
    maxLength: 10,
  },
  {
    name: "CK",
    code: "+682",
    maxLength: 9,
  },
  {
    name: "CR",
    code: "+506",
    maxLength: 10,
  },
  {
    name: "CI",
    code: "+225",
    maxLength: 10,
  },
  {
    name: "HR",
    code: "+385",
    maxLength: 13,
  },
  {
    name: "CU",
    code: "+53",
    maxLength: 12,
  },
  {
    name: "CW",
    code: "+599",
    maxLength: 9,
  },
  {
    name: "CY",
    code: "+357",
    maxLength: 12,
  },
  {
    name: "CZ",
    code: "+420",
    maxLength: 12,
  },
  {
    name: "DK",
    code: "+45",
    maxLength: 8,
  },
  {
    name: "DJ",
    code: "+253",
    maxLength: 8,
  },
  {
    name: "DM",
    code: "+1767",
    maxLength: 11,
  },
  {
    name: "DO",
    code: "+1849",
    maxLength: 11,
  },
  {
    name: "EC",
    code: "+593",
    maxLength: 10,
  },
  {
    name: "EG",
    code: "+20",
    maxLength: 11,
  },
  {
    name: "SV",
    code: "+503",
    maxLength: 8,
  },
  {
    name: "GQ",
    code: "+240",
    maxLength: 8,
  },
  {
    name: "ER",
    code: "+291",
    maxLength: 8,
  },
  {
    name: "EE",
    code: "+372",
    maxLength: 11,
  },
  {
    name: "ET",
    code: "+251",
    maxLength: 9,
  },
  {
    name: "FK",
    code: "+500",
    maxLength: 11,
  },
  {
    name: "FO",
    code: "+298",
    maxLength: 9,
  },
  {
    name: "FJ",
    code: "+679",
    maxLength: 8,
  },
  {
    name: "FI",
    code: "+358",
    maxLength: 12,
  },
  {
    name: "FR",
    code: "+33",
    maxLength: 12,
  },
  {
    name: "PF",
    code: "+689",
    maxLength: 11,
  },
  {
    name: "GA",
    code: "+241",
    maxLength: 9,
  },
  {
    name: "GM",
    code: "+220",
    maxLength: 8,
  },
  {
    name: "GE",
    code: "+995",
    maxLength: 10,
  },
  {
    name: "DE",
    code: "+49",
    maxLength: 13,
  },
  {
    name: "GH",
    code: "+233",
    maxLength: 9,
  },
  {
    name: "GI",
    code: "+350",
    maxLength: 8,
  },
  {
    name: "GR",
    code: "+30",
    maxLength: 12,
  },
  {
    name: "GL",
    code: "+299",
    maxLength: 8,
  },
  {
    name: "GD",
    code: "+1473",
    maxLength: 11,
  },
  {
    name: "GU",
    code: "+1671",
    maxLength: 11,
  },
  {
    name: "GT",
    code: "+502",
    maxLength: 8,
  },
  {
    name: "GG",
    code: "+44",
    maxLength: 12,
  },
  {
    name: "GN",
    code: "+224",
    maxLength: 8,
  },
  {
    name: "GW",
    code: "+245",
    maxLength: 8,
  },
  {
    name: "HT",
    code: "+509",
    maxLength: 8,
  },
  {
    name: "HN",
    code: "+504",
    maxLength: 8,
  },
  {
    name: "HK",
    code: "+852",
    maxLength: 8,
  },
  {
    name: "HU",
    code: "+36",
    maxLength: 9,
  },
  {
    name: "IS",
    code: "+354",
    maxLength: 7,
  },
  {
    name: "IN",
    code: "+91",
    maxLength: 10,
  },
  {
    name: "ID",
    code: "+62",
    maxLength: 11,
  },
  {
    name: "IR",
    code: "+98",
    maxLength: 11,
  },
  {
    name: "IQ",
    code: "+964",
    maxLength: 10,
  },
  {
    name: "IE",
    code: "+353",
    maxLength: 10,
  },
  {
    name: "IM",
    code: "+44",
    maxLength: 11,
  },
  {
    name: "IL",
    code: "+972",
    maxLength: 11,
  },
  {
    name: "IT",
    code: "+39",
    maxLength: 12,
  },
  {
    name: "JM",
    code: "+1876",
    maxLength: 11,
  },
  {
    name: "JP",
    code: "+81",
    maxLength: 11,
  },
  {
    name: "JE",
    code: "+44",
    maxLength: 12,
  },
  {
    name: "JO",
    code: "+962",
    maxLength: 11,
  },
  {
    name: "KZ",
    code: "+7",
    maxLength: 11,
  },
  {
    name: "KE",
    code: "+254",
    maxLength: 9,
  },
  {
    name: "KI",
    code: "+686",
    maxLength: 8,
  },
  {
    name: "KP",
    code: "+850",
    maxLength: 10,
  },
  {
    name: "KR",
    code: "+82",
    maxLength: 11,
  },
  {
    name: "KW",
    code: "+965",
    maxLength: 8,
  },
  {
    name: "KG",
    code: "+996",
    maxLength: 9,
  },
  {
    name: "LA",
    code: "+856",
    maxLength: 9,
  },
  {
    name: "LV",
    code: "+371",
    maxLength: 8,
  },
  {
    name: "LB",
    code: "+961",
    maxLength: 8,
  },
  {
    name: "LS",
    code: "+266",
    maxLength: 9,
  },
  {
    name: "LR",
    code: "+231",
    maxLength: 7,
  },
  {
    name: "LY",
    code: "+218",
    maxLength: 10,
  },
  {
    name: "LI",
    code: "+423",
    maxLength: 10,
  },
  {
    name: "LT",
    code: "+370",
    maxLength: 8,
  },
  {
    name: "LU",
    code: "+352",
    maxLength: 8,
  },
  {
    name: "MO",
    code: "+853",
    maxLength: 8,
  },
  {
    name: "MK",
    code: "+389",
    maxLength: 9,
  },
  {
    name: "MG",
    code: "+261",
    maxLength: 10,
  },
  {
    name: "MW",
    code: "+265",
    maxLength: 9,
  },
  {
    name: "MY",
    code: "+60",
    maxLength: 10,
  },
  {
    name: "MV",
    code: "+960",
    maxLength: 7,
  },
  {
    name: "ML",
    code: "+223",
    maxLength: 8,
  },
  {
    name: "MT",
    code: "+356",
    maxLength: 8,
  },
  {
    name: "MH",
    code: "+692",
    maxLength: 7,
  },
  {
    name: "MQ",
    code: "+596",
    maxLength: 11,
  },
  {
    name: "MR",
    code: "+222",
    maxLength: 9,
  },
  {
    name: "MU",
    code: "+230",
    maxLength: 8,
  },
  {
    name: "MX",
    code: "+52",
    maxLength: 13,
  },
  {
    name: "FM",
    code: "+691",
    maxLength: 7,
  },
  {
    name: "MD",
    code: "+373",
    maxLength: 8,
  },
  {
    name: "MC",
    code: "+377",
    maxLength: 9,
  },
  {
    name: "MN",
    code: "+976",
    maxLength: 8,
  },
  {
    name: "ME",
    code: "+382",
    maxLength: 9,
  },
  {
    name: "MS",
    code: "+1664",
    maxLength: 10,
  },
  {
    name: "MA",
    code: "+212",
    maxLength: 9,
  },
  {
    name: "MZ",
    code: "+258",
    maxLength: 9,
  },
  {
    name: "MM",
    code: "+95",
    maxLength: 9,
  },
  {
    name: "NA",
    code: "+264",
    maxLength: 9,
  },
  {
    name: "NR",
    code: "+674",
    maxLength: 7,
  },
  {
    name: "NP",
    code: "+977",
    maxLength: 10,
  },
  {
    name: "NL",
    code: "+31",
    maxLength: 10,
  },
  {
    name: "NZ",
    code: "+64",
    maxLength: 12,
  },
  {
    name: "NI",
    code: "+505",
    maxLength: 8,
  },
  {
    name: "NE",
    code: "+227",
    maxLength: 8,
  },
  {
    name: "NG",
    code: "+234",
    maxLength: 11,
  },
  {
    name: "NU",
    code: "+683",
    maxLength: 4,
  },
  {
    name: "NF",
    code: "+672",
    maxLength: 8,
  },
  {
    name: "MP",
    code: "+1670",
    maxLength: 10,
  },
  {
    name: "NO",
    code: "+47",
    maxLength: 8,
  },
  {
    name: "OM",
    code: "+968",
    maxLength: 8,
  },
  {
    name: "PK",
    code: "+92",
    maxLength: 12,
  },
  {
    name: "PW",
    code: "+680",
    maxLength: 7,
  },
  {
    name: "PS",
    code: "+970",
    maxLength: 9,
  },
  {
    name: "PA",
    code: "+507",
    maxLength: 8,
  },
  {
    name: "PG",
    code: "+675",
    maxLength: 7,
  },
  {
    name: "PY",
    code: "+595",
    maxLength: 9,
  },
  {
    name: "PE",
    code: "+51",
    maxLength: 12,
  },
  {
    name: "PH",
    code: "+63",
    maxLength: 12,
  },
  {
    name: "PN",
    code: "+64",
    maxLength: 7,
  },
  {
    name: "PL",
    code: "+48",
    maxLength: 9,
  },
  {
    name: "PT",
    code: "+351",
    maxLength: 9,
  },
  {
    name: "PR",
    code: "+1",
    maxLength: 10,
  },
  {
    name: "QA",
    code: "+974",
    maxLength: 8,
  },
  {
    name: "RO",
    code: "+40",
    maxLength: 10,
  },
  {
    name: "RU",
    code: "+7",
    maxLength: 11,
  },
  {
    name: "RW",
    code: "+250",
    maxLength: 9,
  },
  {
    name: "KN",
    code: "+1869",
    maxLength: 10,
  },
  {
    name: "LC",
    code: "+1758",
    maxLength: 10,
  },
  {
    name: "WS",
    code: "+685",
    maxLength: 7,
  },
  {
    name: "SM",
    code: "+378",
    maxLength: 9,
  },
  {
    name: "ST",
    code: "+239",
    maxLength: 9,
  },
  {
    name: "SA",
    code: "+966",
    maxLength: 9,
  },
  {
    name: "SN",
    code: "+221",
    maxLength: 9,
  },
  {
    name: "RS",
    code: "+381",
    maxLength: 11,
  },
  {
    name: "SC",
    code: "+248",
    maxLength: 7,
  },
  {
    name: "SL",
    code: "+232",
    maxLength: 8,
  },
  {
    name: "SG",
    code: "+65",
    maxLength: 8,
  },
  {
    name: "SX",
    code: "+1721",
    maxLength: 11,
  },
  {
    name: "SK",
    code: "+421",
    maxLength: 9,
  },
  {
    name: "SI",
    code: "+386",
    maxLength: 8,
  },
  {
    name: "SB",
    code: "+677",
    maxLength: 7,
  },
  {
    name: "SO",
    code: "+252",
    maxLength: 9,
  },
  {
    name: "ZA",
    code: "+27",
    maxLength: 10,
  },
  {
    name: "SS",
    code: "+211",
    maxLength: 9,
  },
  {
    name: "ES",
    code: "+34",
    maxLength: 9,
  },
  {
    name: "LK",
    code: "+94",
    maxLength: 10,
  },
  {
    name: "SD",
    code: "+249",
    maxLength: 9,
  },
  {
    name: "SR",
    code: "+597",
    maxLength: 7,
  },
  {
    name: "SZ",
    code: "+268",
    maxLength: 8,
  },
  {
    name: "SE",
    code: "+46",
    maxLength: 11,
  },
  {
    name: "CH",
    code: "+41",
    maxLength: 11,
  },
  {
    name: "SY",
    code: "+963",
    maxLength: 10,
  },
  {
    name: "TW",
    code: "+886",
    maxLength: 10,
  },
  {
    name: "TJ",
    code: "+992",
    maxLength: 9,
  },
  {
    name: "TZ",
    code: "+255",
    maxLength: 10,
  },
  {
    name: "TH",
    code: "+66",
    maxLength: 9,
  },
  {
    name: "TG",
    code: "+228",
    maxLength: 8,
  },
  {
    name: "TK",
    code: "+690",
    maxLength: 7,
  },
  {
    name: "TO",
    code: "+676",
    maxLength: 7,
  },
  {
    name: "TT",
    code: "+1868",
    maxLength: 10,
  },
  {
    name: "TN",
    code: "+216",
    maxLength: 8,
  },
  {
    name: "TR",
    code: "+90",
    maxLength: 11,
  },
  {
    name: "TM",
    code: "+993",
    maxLength: 8,
  },
  {
    name: "TC",
    code: "+1649",
    maxLength: 10,
  },
  {
    name: "TV",
    code: "+688",
    maxLength: 7,
  },
  {
    name: "UG",
    code: "+256",
    maxLength: 9,
  },
  {
    name: "UA",
    code: "+380",
    maxLength: 12,
  },
  {
    name: "AE",
    code: "+971",
    maxLength: 9,
  },
  {
    name: "GB",
    code: "+44",
    maxLength: 11,
  },
  {
    name: "UY",
    code: "+598",
    maxLength: 10,
  },
  {
    name: "UZ",
    code: "+998",
    maxLength: 9,
  },
  {
    name: "VU",
    code: "+678",
    maxLength: 7,
  },
  {
    name: "VE",
    code: "+58",
    maxLength: 12,
  },
  {
    name: "VN",
    code: "+84",
    maxLength: 10,
  },
  {
    name: "VI",
    code: "+1",
    maxLength: 10,
  },
  {
    name: "YE",
    code: "+967",
    maxLength: 8,
  },
  {
    name: "ZM",
    code: "+260",
    maxLength: 10,
  },
  {
    name: "ZW",
    code: "+263",
    maxLength: 9,
  },
];