"use client";
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import "../../../../styles/filterNavbar/filterNavbar.scss";
import Image from "next/image";
import { whiteLogo } from "@/public/assets";
import { DrawerComponent } from "../../CommonComponents";

const FilterNavMenu = (props) => {
  const {
    filterValueJson,
    filterValue,
    onClose,
    open,
    drawerData,
    componentRef,
    defaultClose,
    handleFilterData,
    handleDropDownSearchValue,
    handleFilterChange,
    dropdownSearchValue,
    handleCloseFilterData,
    getRegionNames,
    isDrawerOpen,
    showDrawer,
    handleScrollToTop,
  } = props;
  return (
    <div className={`${"filter_menu_main"}`}>
      <div
        className={`${"filter_menu_wrap"} ${
          open ? "filter_menu_open" : "filter_menu_close"
        }`}
      >
        <div className={"filter_menu"}>
          <div className={"filter_menu_logo_main"}>
            <Image
              src={whiteLogo}
              alt={""}
              className={"logo"}
              onClick={() => handleScrollToTop()}
            />
          </div>
          <div className={`${"filter_menu_list_wrap"}`}>
            <div>
              <p className={"filter_menu_title"}>Filters</p>
            </div>
            <div ref={componentRef} className={"menu_wrap"}>
              <div className={"menu_list_main"}>
                <div
                  onClick={() => showDrawer("popular")}
                  className={"popular_dropdown_main"}
                >
                  <span>Popular</span>
                  <DownOutlined
                    className={
                      isDrawerOpen?.popular ? "dropdown_rotate_icon" : ""
                    }
                  />
                </div>
                <div
                  onClick={() => showDrawer("typography")}
                  className={"typography_dropdown_main"}
                >
                  <span>Typography</span>
                  <DownOutlined
                    className={
                      isDrawerOpen?.typography ? "dropdown_rotate_icon" : ""
                    }
                  />
                </div>
                <div
                  onClick={() => showDrawer("country")}
                  className={"country-dropdown-main"}
                >
                  <span>Country</span>
                  <DownOutlined
                    className={
                      isDrawerOpen?.country ? "dropdown_rotate_icon" : ""
                    }
                  />
                </div>
              </div>
              <DrawerComponent
                {...{
                  filterValueJson,
                  filterValue,
                  onClose,
                  open,
                  data: drawerData,
                  defaultClose,
                  handleFilterData,
                  handleDropDownSearchValue,
                  handleFilterChange,
                  dropdownSearchValue,
                  handleCloseFilterData,
                  getRegionNames,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterNavMenu;
