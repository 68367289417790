"use client";
import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import "../../../../../styles/support/support.scss";
import { Collapse } from "antd";
import { BackButtonComponent } from "@/src/components/CommonComponents";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Account Suspensions",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Grounds for Suspension:</h1>
          <p className={"design_account_question_answers"}>
            Uxvortex accounts are typically suspended due to violations of our
            Guidelines. Common reasons include:
          </p>
          <ul className={"forgot_password_details"}>
            <li>Misuse of social gestures such as mass liking, or Feedback.</li>
            <li>
              Requesting others to view your work or follow you through Feedback
            </li>
            <li>Sharing shots that do not qualify as design work.</li>
            <li>
              Exclusively using a Uxvortex account for advertising purposes.
            </li>
            <li>nstances of plagiarism.</li>
          </ul>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Action If Suspended:</h1>
          <p className={"design_account_question_answers"}>
            If your account has been suspended, please reach out to our Support
            team. We will guide you through the necessary steps to potentially
            regain access to your account.
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            Repeat Offenses and Permanent Suspension:
          </h1>
          <p className={"design_account_question_answers"}>
            Repeated violations of our guidelines may lead to permanent account
            suspension. It is crucial to adhere to the Guidelines to maintain a
            positive and respectful environment on Uxvortex.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Blocking Users",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>
            Restrictions Through Blocking:
          </h1>
          <p className={"design_account_question_answers"}>
            UBlocking a user on Uxvortex restricts their ability to:
          </p>
          <ul className={"forgot_password_details"}>
            <li>Follow you</li>
            <li>Leave Feedback on your shots</li>
            <li>Add you to Lists</li>
            <li>Add your shots to Collections</li>
          </ul>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Blocking Process:</h1>
          <p className={"design_account_question_answers"}>
            To block a Uxvortex user, navigate to their profile page, open the
            user menu, and select "Block [user]."
          </p>
        </div>
        <div className={"account_questions_main"}>
          <h1 className={"account_questions"}>Removing a Block:</h1>
          <p className={"design_account_question_answers"}>
            If you wish to unblock a user you had previously blocked, you can do
            so by selecting "Unblock [user]" through the user menu.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Reporting Violations",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            In cases where a user has violated, it is advisable to report them
            along with the blocking action. This ensures appropriate actions are
            taken based on the reported violations.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
];

const MisuseView = () => {
  const [isCollapsed, setIsCollapsed] = useState(["1", "2"]);
  const panelStyle = {
    marginBottom: 24,
    border: "none",
    maxWidth: "800px",
    margin: "0 auto",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: isCollapsed?.length <= 0 ? "calc(100vh - 374px)" : "auto",
        // }}
        items={getItems(panelStyle)}
        onChange={(e) => setIsCollapsed(e)}
        className={`${"support_section_page_main"} ${
          isCollapsed?.length > 1 ? "open" : "misue_close"
        }`}
      />
    </div>
  );
};

export default MisuseView;
