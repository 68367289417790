"use client";
import Link from "next/link";
import React from "react";
import "../../../../styles/support/support.scss";
import { Col, Row } from "antd";
import { BackButtonComponent } from "../../CommonComponents";

const SupportComponent = () => {
  return (
    <React.Fragment>
      <div className={"support-page-wrap"}>
        <BackButtonComponent classNames={"support_page_back_button"} url="/" />
        <div className={"support_page_wrap"}>
          <h1 className={"help_center_text"}>Help Center</h1>
          <div className={"support_page_main"}>
            <Row gutter={[40, 40]}>
              <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <div className={"accounts_main"}>
                  <h1 className={"account_support_title"}>Account Support</h1>
                  <div className={"account_supports_links"}>
                    <Link
                      href={"/support/management"}
                      className={"account_link_text"}
                    >
                      Managing Profiles
                    </Link>
                    <Link
                      href={"/support/email-assistance"}
                      className={"account_link_text"}
                    >
                      Email Assistance
                    </Link>
                    <Link
                      href={"/support/designer-account-services"}
                      className={"account_link_text"}
                    >
                      Designer Account Services
                    </Link>
                  </div>
                </div>
              </Col>
              <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <div className={"accounts_main"}>
                  <h1 className={"account_support_title"}>About UxVortex</h1>
                  <div className={"account_supports_links"}>
                    <Link
                      href={"/support/misuse"}
                      className={"account_link_text"}
                    >
                      Misuse
                    </Link>
                    <Link
                      href={"support/applications-apis"}
                      className={"account_link_text"}
                    >
                      Applications, Integrations & APIs
                    </Link>
                  </div>
                </div>
              </Col>
              <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <div className={"accounts_main"}>
                  <h1 className={"account_support_title"}>
                    Hiring on UxVortex
                  </h1>
                  <div className={"account_supports_links"}>
                    <Link
                      href={"/support/hiring-essentials"}
                      className={"account_link_text"}
                    >
                      Hiring Essentials
                    </Link>
                  </div>
                </div>
              </Col>
              <Col span={24} xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                <div className={"accounts_main"}>
                  <h1 className={"account_support_title"}>For Designers</h1>
                  <div className={"account_supports_links"}>
                    <Link
                      href={"/support/showcasing-your-work"}
                      className={"account_link_text"}
                    >
                      Showcasing Your Work
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className={"contact_text_main"}>
            <h2>Do you need additional assistance?</h2>
            <p>Our friendly support team is here to help.</p>
            <div className={"contact_btn_main"}>
              <a
                href="mailto:mail@uxvortex.com"
                className={"contact_detail_btn"}
              >
                Contact <span>UxVortex</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportComponent;
