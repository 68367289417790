import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { AUTH_TOKEN } from "../const/primitive";
import { useSelector } from "react-redux";
import { apiConst } from "../const/apiConst";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const { profileData } = useSelector((state) => state.profile);
  useEffect(() => {
    if (!profileData?.socketId || socket) return;
    const newSocket = io(apiConst.BASE_URL, {
      auth: {
        token: localStorage.getItem(AUTH_TOKEN),
        role: "user",
      },
    });
    setSocket(newSocket);
    newSocket.emit("join_notification", profileData?.socketId);
    return () => {
      newSocket.close();
      setSocket(null);
    };
  }, [profileData?.socketId]);

  return socket;
};

export default useSocket;
