"use client";
import { Divider, Popover } from "antd";
import {
  ButtonComponent,
  ImageComponent,
} from "@/src/components/CommonComponents";
import Image from "next/image";
import Link from "next/link";
import React, { useLayoutEffect } from "react";
import "../../../../../styles/navbar/navbar.scss";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import {
  allNotificationsRec,
  categoryList,
  getAllFontFamily,
  notificationsId,
  styleList,
  userProfileData,
  manageCollections,
  allSocialProfile,
} from "@/src/redux/reducers/slices";
import { useDispatch, useSelector } from "react-redux";
import { topTenRecord } from "@/src/redux/reducers/topWebReducer/TopWebReducer";
import {
  collectionIcon,
  logOutIcon,
  notification,
  profileAvatar,
  profileIcon,
  settingIcon,
} from "../../../../../public/assets";
import { handleSignOut } from "@/src/utils/handlesignout/handleSignout";
import UserProfileImage from "@/src/components/CommonComponents/userProfileImage/userProfileImage";

const IsLogIn = ({
  token,
  profileRec,
  collectionData,
  notifications,
  topTenWebsitesRec,
  categoryRes,
  styleRes,
  fontAdminRes,
  openProfileMenu,
  handleCloseProfileMenu,
  socialData,
}) => {
  const { setIsAuthModal } = useNav();
  const { profileData } = useSelector((state) => state.profile);
  const { allNotifications } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(userProfileData(profileRec));
    dispatch(allNotificationsRec(notifications?.notifications));
    dispatch(notificationsId(notifications?.notificationId));
    dispatch(topTenRecord(topTenWebsitesRec?.data));
    dispatch(categoryList(categoryRes));
    dispatch(styleList(styleRes));
    dispatch(getAllFontFamily(fontAdminRes));
    dispatch(manageCollections(collectionData));
    dispatch(allSocialProfile(socialData?.allSocialSites));
  }, []);

  const getCharacter = () => {
    let nameLetters;
    if (profileData?.firstName && profileData?.lastName) {
      nameLetters =
        profileData?.firstName?.charAt(0).toUpperCase() +
        profileData?.lastName?.charAt(0).toUpperCase();
    } else if (profileData?.firstName) {
      nameLetters = profileData?.firstName?.charAt(0).toUpperCase();
    } else if (profileData?.lastName) {
      nameLetters = profileData?.lastName?.charAt(0).toUpperCase();
    } else {
      nameLetters = "";
    }

    return nameLetters;
  };

  const handleOpenAuthModal = () => {
    setIsAuthModal(true);
  };

  const NAV_DROP_CONTENT = (
    <div className={"profile-list-main"}>
      <div className="profile-popover-main">
        <UserProfileImage
          profileImage={profileData?.profileImg}
          firstName={profileData?.firstName}
          lastName={profileData?.lastName}
          ImageClassName={"user-avatar"}
          characterClassName={"profile-character"}
        />
        <p className="profile-name">
          {profileData?.firstName} {profileData?.lastName}
        </p>
      </div>
      <Divider className={"divider-main"} />
      <Link href={"/profile"} className={"profile-list"}>
        <Image
          src={profileIcon}
          alt="profileIcon"
          height={19}
          width={19}
          className="profile-icon"
        />
        My Profile
      </Link>
      <Link href={"/collection"} className={"profile-list"}>
        <Image
          src={collectionIcon}
          alt="profileIcon"
          height={19}
          width={19}
          className="profile-icon"
        />
        My Collection
      </Link>
      <Link href={"/settings"} className={"profile-list"}>
        <Image
          src={settingIcon}
          alt="settingIcon"
          height={19}
          width={19}
          className="profile-icon"
        />
        Settings
      </Link>
      <Divider className={"divider-main"} />
      <Link href={"/"} onClick={handleSignOut} className={"log-out-btn"}>
        <Image
          src={logOutIcon}
          alt="logOutIcon"
          height={19}
          width={19}
          className="profile-icon"
        />
        Sign out
      </Link>
    </div>
  );

  return (
    <div className={"sign-in-up-btn"}>
      <div className={"navbar-buttons"}>
        {token ? (
          <div className={"navbar-icon-main"}>
            <div className={"notification-count-main"}>
              <Link href={"/notification"} className={"notification-icon-main"}>
                <ImageComponent
                  imageSrc={notification}
                  imageAlt="notification"
                  imageClassName={"notification-icon"}
                />
              </Link>
              <span className={"notification-count"}>
                {allNotifications?.filter((obj) => !obj?.isRead)?.length || 0}
              </span>
            </div>
            <Popover
              content={NAV_DROP_CONTENT}
              trigger="click"
              placement="bottom"
              arrow={false}
              overlayClassName={"profile-menu"}
              open={openProfileMenu}
              onOpenChange={handleCloseProfileMenu}
              className="popover"
            >
              {profileData?.profileImg ? (
                <Image
                  height={35}
                  width={35}
                  src={profileData?.profileImg}
                  alt={`${profileData?.firstName}`}
                  className={"profile-avatar"}
                />
              ) : profileData?.firstName || profileData?.lastName ? (
                <div className={"profile-name-characters"}>
                  {getCharacter()}
                </div>
              ) : (
                <Image
                  height={35}
                  width={35}
                  src={profileAvatar}
                  alt="profile"
                  className={"profile-avatar"}
                />
              )}
            </Popover>
          </div>
        ) : (
          <div className={"nav-log-in-buttons"}>
            <ButtonComponent
              btnText="Sign In"
              handleClick={handleOpenAuthModal}
              classNames="nav-btn"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IsLogIn;
