import { Form, Steps } from "antd";
import React from "react";
import "../../../../../styles/submitWebsite/websiteSubmit.scss";
import FormContainer from "./Form/FormContainer";
import DetailedInformationContainer from "./DetailedInformation/DetailedInformationContainer";
import FinishingUpContainer from "./FinishingUp/FinishingUpContainer";

const SubmitView = ({
  screenSize,
  submitWebsiteData,
  websiteError,
  categoryData,
  stylesData,
  image,
  fontFamilyData,
  isStatus,
  submitWebImage,
  fontsArray,
  setSubmitWebsiteData,
  handleSubmitWebsiteData,
  handleSubmitWebsitePrevStep,
  current,
  handleGetWebScapDetails,
  setIsAlreadyExist,
  setData,
  data,
  setWebsiteError,
  setImage,
  setCurrent,
  isAlreadyExist,
  isPrevious,
  setIsPrevious,
  setImageSize,
  token,
  setIsAuthModal,
  profileData,
}) => {
  const steps = [
    {
      title: "Website Details",
      content: "First-content",
    },
    {
      title: "Website Properties",
      content: "Second-content",
    },
    {
      title: "Upload Image",
      content: "Last-content",
    },
  ];
  return (
    <Form className={"submit-website-form-main"}>
      <div className={"submit-steps-main"}>
        <Steps
          progressDot
          current={current}
          direction={screenSize > 768 ? "vertical" : "horizontal"}
          items={steps}
          className={"step-component"}
        />
        {current === 0 ? (
          <FormContainer
            {...{
              websiteError,
              setSubmitWebsiteData,
              submitWebsiteData,
              setIsAlreadyExist,
              handleSubmitWebsitePrevStep,
              current,
              setCurrent,
              isAlreadyExist,
              setWebsiteError,
              setIsPrevious,
              token,
              setIsAuthModal,
              profileData,
            }}
          />
        ) : current === 1 ? (
          <DetailedInformationContainer
            {...{
              websiteError,
              setData,
              data,
              setSubmitWebsiteData,
              categoryData,
              stylesData,
              submitWebImage,
              submitWebsiteData,
              setWebsiteError,
              handleSubmitWebsitePrevStep,
              current,
              setCurrent,
              isPrevious,
              setIsPrevious,
            }}
          />
        ) : (
          <FinishingUpContainer
            {...{
              isStatus,
              websiteError,
              setData,
              data,
              setSubmitWebsiteData,
              fontFamilyData,
              fontsArray,
              submitWebImage,
              submitWebsiteData,
              handleGetWebScapDetails,
              image,
              setImage,
              setWebsiteError,
              handleSubmitWebsitePrevStep,
              handleSubmitWebsiteData,
              current,
              setCurrent,
              setImageSize,
            }}
          />
        )}
      </div>
    </Form>
  );
};

export default SubmitView;
