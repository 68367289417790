"use client";
import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "../../../../../styles/support/support.scss";
import { BackButtonComponent } from "@/src/components/CommonComponents";

const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Recovering Deleted Shots",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            Regrettably, once a shot is deleted, we lack the capability to
            restore the work to your portfolio. If you wish to showcase the shot
            again, our suggestion is to repost it on your profile.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Removing a Uxvortex Shot",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            To remove a shot, login to your account. Go on profile section &gt;
            Approved &gt; Three dots &gt; Delete website &gt; Enter URL &gt;
            Delete
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: "Modifying a Uxvortex Shot",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            To make changes to an already published shot, access the shot view
            and locate the edit icon. It's important to note that while you can
            edit the title, description, or tags associated with your shots, you
            won't be able to modify the uploaded image. If your shot has
            garnered significant views and likes, it's advisable to keep it
            live. Alternatively, you can create a Rebound with the updated
            image. In the case of a multi-shot post, you can edit all images in
            the post except for the main image.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
];
const ShowCasingYourWorkView = () => {
  const [isCollapsed, setIsCollapsed] = useState(["1", "2"]);
  const panelStyle = {
    marginBottom: 24,
    border: "none",
    maxWidth: "800px",
    margin: "0 auto",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
        onChange={(e) => setIsCollapsed(e)}
        className={`${"support_section_page_main"} ${
          isCollapsed?.length > 1 ? "showing_open" : "showing_close"
        }`}
      />
    </div>
  );
};

export default ShowCasingYourWorkView;
