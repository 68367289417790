import React from "react";
import { Col, Row } from "antd";
import { noNotification } from "@/public/assets";
import {
  ButtonComponent,
  ImageComponent,
} from "@/src/components/CommonComponents";
import { getNotificationData } from "@/src/const/nonPrimitive";
import { formattedDate, isEmpty } from "@/src/utils";
import { LoadingOutlined, RedoOutlined } from "@ant-design/icons";
import Image from "next/image";
import "../../../../../styles/notification/notification.scss";

const NotificationView = ({
  handleMarkAsRead,
  readNotifications,
  unReadNotifications,
  profileData,
  handleReloadData,
  isLoading,
}) => {
  return (
    <div className={`${"notification-page"} `}>
      <div
        className={` ${"notification-div"} ${
          isEmpty(unReadNotifications) && isEmpty(readNotifications)
            ? profileData && profileData?.auth?.isVerified
              ? isEmpty(unReadNotifications) && isEmpty(readNotifications)
                ? "notification-message-no-record"
                : "notification-page-main"
              : "profile-verified"
            : ""
        }`}
      >
        {/* <BackButton classNames={"notification-page-back-btn"} /> */}

        <div className={"message-notification-main"}>
          <div className={"message-notification-header-main"}>
            <div className={"unread-notification-main"}>
              <div className={"unread-message-wrap"}>
                <h3 className={`${"title"} ${"popover-notification-title"}`}>
                  Unread Notifications
                </h3>
                <ButtonComponent
                  btnText={
                    isLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <RedoOutlined
                        onClick={handleReloadData}
                        // className={"reload-btn-main"}
                      />
                    )
                  }
                  handleClick={isLoading ? () => {} : handleReloadData}
                  classNames={"reload-btn-main"}
                />
              </div>
              {!isEmpty(unReadNotifications) && (
                <div className={"view-more"}>
                  <ButtonComponent
                    btnText="Mark as All Read"
                    handleClick={handleMarkAsRead}
                    classNames={`${"notification-view-more-button"}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={` ${"notification-main"}`}>
            {!isEmpty(unReadNotifications) ? (
              Array?.isArray(unReadNotifications) &&
              unReadNotifications.length > 0 &&
              unReadNotifications?.map((obj) => {
                const notificationMessage = getNotificationData(obj);
                if (!notificationMessage) return;
                const { title, description, image } = notificationMessage;
                return (
                  <Row
                    key={obj?.sentOn}
                    className={`${"notification-wrap"} ${"notification-page-alert"} ${
                      obj?.status === "website_rejected" &&
                      "notification-page-alert-reject"
                    } ${
                      obj?.status === "website_approved" &&
                      "notification-page-alert-approved"
                    } ${
                      obj?.status === "my_website_uploaded" &&
                      "notification-page-alert-upload"
                    } ${
                      obj?.status === "website_status_changed" &&
                      "notification-page-alert-status-changed"
                    } ${
                      obj?.status === "website_review" &&
                      "notification-page-alert-review"
                    }`}
                  >
                    <Col
                      span={24}
                      xxl={20}
                      md={18}
                      sm={12}
                      className={"notification-details"}
                    >
                      <ImageComponent
                        imageSrc={image}
                        imageAlt="status-image"
                        imageClassName={"notification-icon"}
                        imageWidth={100}
                        imageHeight={100}
                      />
                      <div className={"content-wrap"}>
                        <h5 className={"notification-type"}>{title}</h5>
                        <p className={"notification-message"}>{description}</p>
                      </div>
                    </Col>
                    <Col
                      span={24}
                      xxl={4}
                      md={6}
                      sm={12}
                      className={"close-date-time"}
                    >
                      <p className={"notification-date-time"}>
                        {formattedDate(obj?.sentOn)}
                      </p>
                      {/* <CloseOutlined className={"close_icon"} /> */}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <>
                <Image
                  src={noNotification}
                  alt="notification"
                  className={"no-notification-image"}
                />
                <p className={"no-notification-text"}>No new notifications</p>
              </>
            )}
          </div>
        </div>
        <div className={"message-notification-main"}>
          <div className="read-message-wrap">

          <h3 className={`${"title"} ${"popover-notification-title"}`}>
            Read Notifications
          </h3>
          </div>
          <div className={` ${"notification-main"}`}>
            {!isEmpty(readNotifications) ? (
              Array?.isArray(readNotifications) &&
              readNotifications.length > 0 &&
              readNotifications
                ?.sort((a, b) => b.sentOn - a.sentOn)
                ?.map((obj) => {
                  const notificationMessage = getNotificationData(obj);
                  if (!notificationMessage) return;
                  const { title, description, image } = notificationMessage;
                  return (
                    <Row
                      key={obj?.sentOn}
                      className={`${"notification-wrap"} ${"notification-page-alert"} ${
                        obj?.status === "website_rejected" &&
                        "notification-page-alert-reject"
                      } ${
                        obj?.status === "website_approved" &&
                        "notification-page-alert-approved"
                      } ${
                        obj?.status === "my_website_uploaded" &&
                        "notification-page-alert-upload"
                      } ${
                        obj?.status === "website_status_changed" &&
                        "notification-page-alert-status-changed"
                      } ${
                        obj?.status === "website_review" &&
                        "notification-page-alert-review"
                      }
                      `}
                    >
                      <Col
                        span={24}
                        xxl={20}
                        md={18}
                        sm={12}
                        className={"notification-details"}
                      >
                        <ImageComponent
                          imageSrc={image?.src}
                          imageAlt="status-image"
                          imageClassName={"notification-icon"}
                          imageWidth={100}
                          imageHeight={100}
                        />
                        <div className={"content-wrap"}>
                          <h5 className={"notification-type"}>{title}</h5>
                          <p className={"notification-message"}>
                            {description}
                          </p>
                        </div>
                      </Col>
                      <Col
                        span={24}
                        xxl={4}
                        md={6}
                        sm={12}
                        className={"close-date-time"}
                      >
                        <p className={"notification-date-time"}>
                          {formattedDate(obj?.sentOn)}
                        </p>
                        {/* <CloseOutlined className={"close_icon"} /> */}
                      </Col>
                    </Row>
                  );
                })
            ) : (
              <>
                <Image
                  src={noNotification}
                  alt="notification"
                  className={"no-notification-image"}
                />
                <p className={"no-notification-text"}>No new notifications</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
