"use client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import AuthenticationContainer from "./Auth/AuthContainer";
import IsLogIn from "./IsLoginView";
import Image from "next/image";
import Link from "next/link";
import WebSnapShotContainer from "../WebSnapShot/WebSnapShotContainer";
import "../../../../../styles/navbar/navbar.scss";
import { uxVortex } from "@/public/assets";
import {
  UserVerified,
  isEmpty,
  isTokenValid,
  openNotification,
} from "@/src/utils";
import { handleSignOut } from "@/src/utils/handlesignout/handleSignout";
const NavBarView = ({
  token,
  profileRec,
  notifications,
  topTenWebsitesRec,
  categoryRes,
  styleRes,
  fontAdminRes,
  collectionData,
  socialData,
}) => {
  const [changeClass, setChangeClass] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const changeClassOnScroll = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 55) {
      setChangeClass(true);
      setOpenProfileMenu(false);
    } else {
      setChangeClass(false);
    }
  };

  const handleCloseProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeClassOnScroll);
    }
  }, []);
  useEffect(() => {
    if (isEmpty(token)) return;
    let intervalId;

    const checkTokenValidity = async () => {
      if (!isEmpty(token?.value)) {
        const isValid = await isTokenValid(token?.value);
        if (!isValid) {
          handleSignOut();
          openNotification("Session Expired", "error");
          clearInterval(intervalId);
        }
      }
    };

    checkTokenValidity();
    // eslint-disable-next-line no-restricted-syntax
    intervalId = setInterval(checkTokenValidity, 1000);
    return () => clearInterval(intervalId);
  }, [token]);
  return (
    <React.Fragment>
      <div className={`navbar-wrap ${changeClass ? "navbar-scroll" : ""}`}>
        <div className={"nav-buttons-main"}>
          <Link href={"/submit"} className={"submit-btn"}>
            Submit Website
          </Link>
          <Link href={"/about"} className={"submit-btn"}>
            About Us
          </Link>

          <WebSnapShotContainer
            {...{
              token: token?.value,
            }}
          />
        </div>
        <div
          className={`${"website-logo-main"} ${token?.value ? "signed" : ""}`}
        >
          <Link href={"/"} className={"website-logo"}>
            <Image src={uxVortex} alt={""} className={"logo"} />
          </Link>
        </div>
        <IsLogIn
          {...{
            token: token?.value,
            profileRec,
            notifications,
            topTenWebsitesRec,
            categoryRes,
            styleRes,
            fontAdminRes,
            collectionData,
            openProfileMenu,
            handleCloseProfileMenu,
            socialData,
          }}
        />
        <AuthenticationContainer />
      </div>
      {profileRec?.auth?.isVerified === false && (
        <UserVerified
          {...{
            email: profileRec?.auth?.emailId,
            isVerified: profileRec?.auth?.isVerified,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default NavBarView;
