import React, { useEffect, useState } from "react";
import DetailedInformationView from "./DetailedInformationView";
import { isEmpty } from "@/src/utils";
import { COUNTRY_REQUIRED } from "@/src/const/primitive";
import { INPUT_FIELD_NAME, VALIDATION } from "@/src/const/nonPrimitive";

const DetailedInformationContainer = ({
  setData,
  data,
  setSubmitWebsiteData,
  categoryData,
  stylesData,
  submitWebsiteData,
  handleSubmitWebsitePrevStep,
  setCurrent,
  current,
  isPrevious,
  setIsPrevious,
}) => {
  const [detailedInfo, setDetailedInfo] = useState({
    country: "",
    category: [],
    style: [],
  });
  const [detailedInfoError, setDetailedInfoError] = useState({});
  const updateRecords = (records, name, value, isSelected) => {
    return records?.map((obj) => {
      const key = name === "category" ? "categoryName" : "styleName";
      if (obj?.[key]?.toLowerCase() === value?.toLowerCase()) {
        return {
          ...obj,
          isSelected,
        };
      } else {
        return obj;
      }
    });
  };
  const handleCheckBoxChange = (e, value, name) => {
    const errorsToUpdate = {};
    let checkedVal;
    let disabledData;
    if (e.target.checked) {
      checkedVal = {
        ...detailedInfo,
        [name]: Array.isArray(detailedInfo?.[name])
          ? [...detailedInfo?.[name], value]
          : [value],
      };
      disabledData = {
        ...data,
        categoryRecords: updateRecords(
          data?.categoryRecords,
          name,
          value,
          true
        ),
        styleRecords: updateRecords(data?.styleRecords, name, value, true),
      };
    } else {
      checkedVal = {
        ...detailedInfo,
        [name]: detailedInfo?.[name]?.filter((ele) => ele !== value),
      };
      disabledData = {
        ...data,
        [`${name}Records`]: updateRecords(
          data?.[`${name}Records`],
          name,
          value,
          false
        ),
      };
    }

    if (checkedVal[name]?.length <= 0) {
      errorsToUpdate[name] = VALIDATION?.[name]?.requiredMsg;
    } else if (checkedVal[name]?.length > VALIDATION?.[name]?.maxLength) {
      errorsToUpdate[name] = VALIDATION?.[name]?.maxLengthMsg;
    } else if (checkedVal[name]?.length < VALIDATION?.[name]?.minLength) {
      errorsToUpdate[name] = VALIDATION?.[name]?.minLengthMsg;
    } else {
      errorsToUpdate[name] = "";
    }
    const isMaxSelected =
      checkedVal[name]?.length === VALIDATION?.[name]?.maxLength;
    disabledData = {
      ...data,
      [`${name}Records`]: disabledData?.[`${name}Records`]?.map((obj) => ({
        ...obj,
        isDisabled: isMaxSelected,
      })),
    };
    setData(disabledData);
    setDetailedInfoError({
      ...detailedInfoError,
      ...errorsToUpdate,
      [name]: errorsToUpdate[name],
    });
    if (checkedVal[name]?.length <= VALIDATION?.[name]?.maxLength) {
      setSubmitWebsiteData(checkedVal);
      setDetailedInfo(checkedVal);
    }
  };
  const handleSelectCountry = (code) => {
    if (!isEmpty(code)) {
      setSubmitWebsiteData({ ...submitWebsiteData, country: code });
      setDetailedInfoError({
        ...detailedInfoError,
        country: "",
      });
    } else {
      setDetailedInfoError({
        ...detailedInfoError,
        country: COUNTRY_REQUIRED,
      });
    }
  };
  const handleSubmitWebsiteNextStep = () => {
    const errorsToUpdate = {};
    INPUT_FIELD_NAME?.forEach((ele) => {
      const name = ele?.name?.toLowerCase();
      if (name === "category" || name === "style" || name === "country") {
        if (isEmpty(submitWebsiteData[name])) {
          errorsToUpdate[name] = VALIDATION[name]?.requiredMsg;
        } else if (
          VALIDATION[name]?.maxLength &&
          submitWebsiteData[name]?.length > VALIDATION[name]?.maxLength
        ) {
          errorsToUpdate[name] = VALIDATION[name]?.maxLengthMsg;
        } else if (
          VALIDATION[name]?.minLength &&
          submitWebsiteData[name]?.length < VALIDATION?.[name]?.minLength
        ) {
          errorsToUpdate[name] = VALIDATION[name]?.minLengthMsg;
        } else {
          errorsToUpdate[name] = "";
        }
      }
    });
    setDetailedInfoError({
      ...detailedInfoError,
      ...errorsToUpdate,
      category: errorsToUpdate?.category,
      style: errorsToUpdate?.style,
      country: errorsToUpdate?.country,
    });
    if (
      Object.values(errorsToUpdate).every((value) => !value) &&
      Object.values(detailedInfo).every((value) => value) &&
      Object.values(detailedInfoError).every((value) => !value)
    ) {
      setCurrent(current + 1);
      setIsPrevious(false);
    }
  };
  useEffect(() => {
    setDetailedInfo({
      ...submitWebsiteData,
      country: submitWebsiteData?.country,
      category: submitWebsiteData?.category,
      style: submitWebsiteData?.style,
    });
  }, [submitWebsiteData]);
  return (
    <DetailedInformationView
      {...{
        isPrevious,
        detailedInfoError,
        handleCheckBoxChange,
        handleSelectCountry,
        categoryData,
        stylesData,
        submitWebsiteData: detailedInfo,
        handleSubmitWebsitePrevStep,
        handleSubmitWebsiteNextStep,
        current,
      }}
    />
  );
};

export default DetailedInformationContainer;
