"use client";
import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "../../../../../styles/support/support.scss";
import { BackButtonComponent } from "@/src/components/CommonComponents";
const getItems = (panelStyle) => [
  {
    key: "1",
    label: "Utilizing Work from Uxvortex",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            It's a possibility! Nevertheless, you must reach out to the designer
            who posted a particular shot to address copyright status and payment
            arrangements. Uxvortex serves as a platform for designers to
            showcase their creations, but we don't possess any rights to the
            shots – designers maintain full ownership of their work.
          </p>
          <p className={"profile_details_paragraph"}>
            Kindly refrain from employing any work discovered on Uxvortex
            without obtaining explicit permission from the designer, even for
            personal purposes, as this could lead to legal consequences.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: "Resolving Issues between Clients and Designers",
    children: (
      <div className={"profile_management_main"}>
        <div className={"account_questions_main"}>
          <p className={"design_account_question_answers"}>
            We regret to learn that you're facing challenges with a connection
            made on Uxvortex. Unfortunately, we cannot mediate conflicts between
            clients and designers; it falls outside the scope of our service and
            legal authority. While our platform aids in discovering and reaching
            out to potential designers for projects, any subsequent discussions,
            contracts, or arrangements are the sole responsibility of the
            involved parties.
          </p>
          <p className={"design_account_question_answers"}>
            However, if you believe that a client or designer has engaged in
            malicious or deceptive behaviour, we encourage you to share your
            experience with us through the provided contact form. We keep
            records, and if there are recurring problems with a Uxvortex user,
            we will intervene to ensure Uxvortex remains a secure space for
            finding and hiring designers.
          </p>
        </div>
      </div>
    ),
    style: panelStyle,
  },
];

const HiringEssentialsView = () => {
  const [isCollapsed, setIsCollapsed] = useState(["1", "2"]);
  const panelStyle = {
    marginBottom: 24,
    border: "none",
    maxWidth: "800px",
    margin: "0 auto",
  };

  return (
    <div className={"support-page-wrap"}>
      <BackButtonComponent url="/support" classNames={"support_back_button"} />
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{
        //   background: "transparent",
        //   height: isCollapsed?.length <= 0 ? "calc(100vh - 374px)" : "auto",
        // }}
        items={getItems(panelStyle)}
        onChange={(e) => setIsCollapsed(e)}
        className={`${"support_section_page_main"} ${
          isCollapsed?.length > 1 ? "open" : "hiring_close"
        }`}
      />
    </div>
  );
};

export default HiringEssentialsView;
