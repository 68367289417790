"use client";
import { post, profileAvatar, profileBackground } from "@/public/assets";
import {
  BackButtonComponent,
  ImageComponent,
} from "@/src/components/CommonComponents";
import { isEmpty } from "@/src/utils";
import Image from "next/image";
import React from "react";
import "../../../../../styles/profile/profile.scss";
import Link from "next/link";
import { PROFILE_EMPTY_SOCIAL_ICONS } from "@/src/const/nonPrimitive";

const ProfileInfoView = ({
  profileData,
  userWebsiteData,
  handleGetCharacter,
  socialProfileData,
  loadingSocialProfile,
}) => {
  return (
    <div className="profile-information">
      <BackButtonComponent url={"/"} />
      <div className="profile-details-main">
        <ImageComponent
          imageSrc={profileBackground}
          imageAlt={"profileBackground"}
          imageClassName={"profile-background-image"}
        />
        <div className="profile-image">
          <div className="profile-images">
            {!isEmpty(profileData?.profileImg) ? (
              <Image
                fill
                src={profileData?.profileImg}
                alt={`${profileData?.firstName}`}
                className={"profile-photo"}
              />
            ) : !isEmpty(profileData?.firstName) &&
              !isEmpty(profileData?.lastName) ? (
              <React.Fragment>
                <div className={"profile-character"}>
                  {handleGetCharacter()}
                </div>
              </React.Fragment>
            ) : (
              <Image
                height={35}
                width={35}
                src={profileAvatar}
                alt="profile"
                className={"profile-avatar"}
              />
            )}
          </div>
          <div className="profile-name">
            {profileData?.firstName} {profileData?.lastName}
          </div>
        </div>
        <div className="profile-details">
          <div className="profile-website-details">
            <div className="profile-website-details-wrap">
              <Image
                src={post}
                alt="posts"
                height={31}
                width={31}
                className="details-icon"
              />
              <p className="counter">{userWebsiteData?.length}</p>
              <p className="website-details-title">Posts</p>
            </div>
          </div>
          <div className="profile-social-icons">
            {!isEmpty(profileData?.socialProfile)
              ? Object?.keys(profileData?.socialProfile)?.map((icons) => {
                  const icon = socialProfileData?.find(
                    (ele) => icons === ele?.socialSiteName?.toLowerCase()
                  );
                  return (
                    <div
                      className="profile-link-icon-main"
                      key={icon?.socialSiteId}
                    >
                      <Link
                        href={profileData?.socialProfile?.[icons]}
                        target="_blank"
                      >
                        <Image
                          src={icon?.socialSiteLogo}
                          alt="facebook"
                          height={25}
                          width={25}
                          className="profile-icon"
                        />
                      </Link>
                    </div>
                  );
                })
              : loadingSocialProfile === false &&
                PROFILE_EMPTY_SOCIAL_ICONS?.map((icons) => {
                  return (
                    <div className="icons" key={icons?.socialSiteName}>
                      <Image
                        src={icons?.image}
                        alt="facebook"
                        height={25}
                        width={25}
                        className="profile-icon"
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoView;
