"use client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import NotificationView from "./NotificationView";
import { useDispatch, useSelector } from "react-redux";
import useSocket from "@/src/hooks/useSocket";
import { addNotification } from "@/src/redux/reducers/slices";
import { getAllNotification, markAllAsRead } from "@/src/redux/actions";

const NotificationContainer = () => {
  const [allNotification, setAllNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { allNotifications, notificationId } = useSelector(
    (state) => state.notification
  );
  const { profileData } = useSelector((state) => state.profile);
  const socket = useSocket(profileData?.socketId);
  const dispatch = useDispatch();
  const readNotifications = allNotification?.filter((obj) => obj?.isRead);
  const unReadNotifications = allNotification?.filter((obj) => !obj?.isRead);
  const handleMarkAsRead = async () => {
    const payload = {
      notificationId,
    };
    const res = await dispatch(markAllAsRead(payload));
    setAllNotification(res?.data?.data?.notifications);
  };

  const handleReloadData = async () => {
    setIsLoading(true);
    const res = await getAllNotification();
    const allNotification = res?.notifications;
    setAllNotification(allNotification);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  };

  useLayoutEffect(() => {
    if (!socket) return;
    const handleNotification = (payload) => {
      dispatch(addNotification(payload));
      setAllNotification((prev) => [payload, ...prev]);
    };
    socket?.on("website_status_changed", handleNotification);
    socket?.on("my_website_uploaded", handleNotification);
  }, [socket]);

  useEffect(() => {
    setAllNotification(allNotifications);
  }, [allNotifications]);
  return (
    <NotificationView
      {...{
        profileData,
        allNotification,
        handleMarkAsRead,
        readNotifications,
        unReadNotifications,
        handleReloadData,
        isLoading,
      }}
    />
  );
};

export default NotificationContainer;
