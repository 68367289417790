"use client";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ForgotPasswordView from "./ForgotPasswordView";
import { useDispatch } from "react-redux";
import { useRouter, useSearchParams } from "next/navigation";
import { SPACE_REGEX } from "@/src/const/primitive";
import { addWebsiteValidation } from "@/src/utils/validation/validation";
import { forgotPasswordAction } from "@/src/redux/actions";
import { isEmpty, isTokenValid } from "@/src/utils";

const ForgotPasswordContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isStatus, setIsStatus] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState({});
  const [isPasswordShow, setIsPasswordShow] = useState({});
  const inputRef = useRef();
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const queryToken = searchParams.get("token");
  const router = useRouter();
  useLayoutEffect(() => {
    if (!queryToken) {
      router.push("/");
    }
  }, []);

  useEffect(() => {
    if (queryToken) {
      const token = isTokenValid(queryToken);
      if (token) {
        setIsModalOpen(false);
      } else {
        setIsModalOpen(false);
      }
    }
  }, [queryToken]);

  const handlePasswordChange = (e, FIRST_INDEX, LAST_INDEX) => {
    let { name, value } = e.target;
    value = value.trimStart();
    value = value?.replace(SPACE_REGEX, "");
    const passwordDetails = {
      ...forgotPassword,
      [name]: value?.slice(FIRST_INDEX, LAST_INDEX),
    };
    value?.length < LAST_INDEX && setForgotPassword(passwordDetails);
  };
  const handlePasswordFocus = (maxLength, name) => {
    const { errors } = addWebsiteValidation(
      name,
      forgotPassword,
      forgotPasswordError,
      true
    );
    setForgotPasswordError(errors);
    if (forgotPassword[name]?.length === maxLength) {
      setForgotPasswordError({
        ...forgotPasswordError,
        [name]: "",
      });
    }
  };
  const handleSubmitForgotPassword = async (e) => {
    let inputName = e?.target?.name;
    let names;
    if (!isEmpty(inputRef.current)) {
      if (
        isEmpty(forgotPassword?.password) ||
        isEmpty(forgotPassword?.confirmPassword) ||
        !isEmpty(forgotPasswordError?.password) ||
        !isEmpty(forgotPasswordError?.confirmPassword)
      ) {
        inputRef.current.focus();
      }
    } else {
      let isValidArr = Object.keys(forgotPassword);
      isValidArr?.forEach((val) => {
        names = val;
      });
      const { errors, isValid } = addWebsiteValidation(
        inputName ? inputName : names,
        forgotPassword,
        forgotPasswordError,
        ""
      );
      setForgotPasswordError(errors);
      if (isValid === false) {
        setIsStatus(true);
        const obj = {
          token: queryToken,
          password: forgotPassword?.password,
        };
        setIsSuccessModal(true);
        dispatch(forgotPasswordAction(obj));
        setIsStatus(false);
        router.push("/");
      }
    }
  };
  const handleValidTokenModal = () => {
    router.push("/forgot-password");
  };
  const handleForgotEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitForgotPassword(e);
    }
  };
  const handleIsShowPassword = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: true,
    });
  };
  const handleIsShowPasswordInvisible = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: false,
    });
  };
  return (
    <ForgotPasswordView
      {...{
        isModalOpen,
        inputRef,
        forgotPasswordError,
        forgotPassword,
        handleSubmitForgotPassword,
        handlePasswordChange,
        handleValidTokenModal,
        handleForgotEmailKeyDown,
        handlePasswordFocus,
        isSuccessModal,
        setIsSuccessModal,
        isStatus,
        isPasswordShow,
        handleIsShowPasswordInvisible,
        handleIsShowPassword,
      }}
    />
  );
};

export default ForgotPasswordContainer;
