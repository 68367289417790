import { USER_SETTINGS } from "@/src/const/nonPrimitive";
import React from "react";
import { BackButtonComponent } from "@/src/components/CommonComponents";
import "../../../../../styles/profileSetting/profileSetting.scss";
import dynamic from "next/dynamic";
const GeneralDetailsContainer = dynamic(
  () => import("./General/GeneralDetailsContainer")
);
const UserAccountDetailContainer = dynamic(
  () => import("./Account/UserAccountDetailContainer")
);
const UserSecurityContainer = dynamic(
  () => import("./Security/UserSecurityContainer")
);
const UserSessionContainer = dynamic(
  () => import("./Session/UserSessionContainer")
);
const SocialProfileContainer = dynamic(
  () => import("./SocialProfile/SocialProfileContainer")
);

const SettingsView = ({ handleChangeSettingsName, settingModuleName }) => {
  return (
    <div
      className={`${
        settingModuleName?.toLowerCase() === "security"?.toLowerCase() ||
        settingModuleName?.toLowerCase() === "Social Profiles"?.toLowerCase()
          ? "profile-setting-view-wrap"
          : ""
      } profile-setting-main`}
    >
      <BackButtonComponent url="/" classNames="profile-back-button" />
      <div className="setting-profile">
        <p className="profile-setting-title">Profile Setting</p>
        <div className="profile-setting-navbar">
          {USER_SETTINGS?.map((ele) => {
            return (
              <div
                key={ele}
                onClick={() => handleChangeSettingsName(ele)}
                className={`${settingModuleName?.toLowerCase() === ele?.toLowerCase() ? "profile-menu-active" : ""} profile-menu`}
              >
                {ele}
              </div>
            );
          })}
        </div>
        {settingModuleName?.toLowerCase() === "general"?.toLowerCase() ? (
          <GeneralDetailsContainer />
        ) : settingModuleName?.toLowerCase() === "account"?.toLowerCase() ? (
          <UserAccountDetailContainer />
        ) : settingModuleName?.toLowerCase() === "security"?.toLowerCase() ? (
          <UserSecurityContainer />
        ) : settingModuleName?.toLowerCase() ===
          "Social Profiles"?.toLowerCase() ? (
          <SocialProfileContainer />
        ) : (
          settingModuleName?.toLowerCase() === "session"?.toLowerCase() && (
            <UserSessionContainer />
          )
        )}
      </div>
    </div>
  );
};

export default SettingsView;
