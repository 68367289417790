"use client";
import React, { useEffect, useState } from "react";
import ProfileInfoView from "./ProfileInfoView";
import { useSelector } from "react-redux";

const ProfileInfoContainer = () => {
  const { profileData, userWebsiteData } = useSelector(
    (state) => state.profile
  );
  const [loadingSocialProfile, setLoadingSocialProfile] = useState(true);
  const { socialProfileData } = useSelector((state) => state.settings);

  const handleGetCharacter = () => {
    let nameLetters;
    if (profileData?.firstName && profileData?.lastName) {
      nameLetters =
        profileData?.firstName?.charAt(0).toUpperCase() +
        profileData?.lastName?.charAt(0).toUpperCase();
    } else if (profileData?.firstName) {
      nameLetters = profileData?.firstName?.charAt(0).toUpperCase();
    } else if (profileData?.lastName) {
      nameLetters = profileData?.lastName?.charAt(0).toUpperCase();
    } else {
      nameLetters = "";
    }

    return nameLetters;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingSocialProfile(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ProfileInfoView
      {...{
        loadingSocialProfile,
        profileData,
        userWebsiteData,
        handleGetCharacter,
        socialProfileData,
      }}
    />
  );
};

export default ProfileInfoContainer;
