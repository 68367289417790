import { AD_WEBSITE_FIELD } from "@/src/const/nonPrimitive";
import { isEmpty } from "@/src/utils";
import { Button, Form } from "antd";
import React from "react";
import "../../../../../../styles/submitWebsite/websiteSubmit.scss";
import {
  ButtonComponent,
  InputComponent,
} from "@/src/components/CommonComponents";
const FormView = ({
  formDetailsError,
  submitWebsiteData,
  inputRef,
  handleSubmitWebsitePrevStep,
  current,
  handleSubmitWebsiteNextStep,
  handleFocus,
  handleWebsiteInputChange,
}) => {
  return (
    <div className={`${"submit-website-form-wrap"}`}>
      {AD_WEBSITE_FIELD?.map((obj, i) => {
        return (
          <div key={obj?.label} className={`${"submit-form-wrap"}`}>
            <Form.Item className={`submit-form-main ${[obj?.className]}`}>
              <div className={"input-line"}>
                <div className={`form-details`}>
                  <p className={"details-number"}>0{i + 1}</p>
                  <label className={"form-title"}>{obj?.label}</label>
                </div>
                <InputComponent
                  isSubmitWebsite={true}
                  classNames={"form-input"}
                  type={obj?.type}
                  placeholder={obj?.placeholder}
                  maxLength={obj?.maxLength}
                  name={obj?.name}
                  error={formDetailsError?.[obj?.name]}
                  value={submitWebsiteData?.[obj?.name]}
                  handleChange={(e) =>
                    handleWebsiteInputChange(e, 0, obj?.maxLength)
                  }
                  handleBlur={(e) =>
                    handleFocus(e, obj?.name, obj?.maxLength - 1)
                  }
                  inputRef={
                    isEmpty(submitWebsiteData?.[obj?.name]) ||
                    !isEmpty(formDetailsError?.[obj?.name])
                      ? inputRef
                      : null
                  }
                  errorClass={"website-form-error"}
                />
              </div>
            </Form.Item>
          </div>
        );
      })}
      <div
        className={`submit-button-main fade-up-animation4 form-buttons-main`}
      >
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => handleSubmitWebsitePrevStep()}
          >
            Previous
          </Button>
        )}
        <ButtonComponent
          btnText={"Next"}
          classNames={"next-btn"}
          handleClick={handleSubmitWebsiteNextStep}
        />
      </div>
    </div>
  );
};

export default FormView;
