import React from "react";
import { Drawer } from "antd";
import "../../../../../styles/navbar/navbar.scss";
import {
  ButtonComponent,
  ImageComponent,
  InputComponent,
} from "@/src/components/CommonComponents";
import WebsiteCardCarouselView from "@/src/components/CommonComponents/WebsiteCards/WebsiteCardCarouselView";
import { filterGetColor, isEmpty } from "@/src/utils";
import { LoadingOutlined } from "@ant-design/icons";
import {
  fontLoader,
  searchIcon,
  searchWebsite,
} from "../../../../../public/assets";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const WebSnapShotView = ({
  handleScreenShotOpenDrawer,
  handleScreenShotDrawerChange,
  handleGetScreenShotDetails,
  handleScreenShotOnClose,
  isGetScreenshotDetails,
  getScreenShotData,
  isScreenShotOpen,
  topWebsiteData,
  screenShotData,
  placement,
  token,
  handleFocus,
  websiteError,
  handleOnMouseHover,
  isPortfolioWebsite,
  userOnHoverFilterData,
  drawer,
  setDrawer,
  modal,
  setModal,
}) => {
  return (
    <React.Fragment>
      <div className={"website-screenshot-btn"}>
        <p className={"website-ss-btn"} onClick={handleScreenShotOpenDrawer}>
          Web Snapshot
        </p>
      </div>
      {/* <p onClick={handleScreenShotOpenDrawer}>Web Snapshot</p> */}
      {isScreenShotOpen && (
        <Drawer
          title="Fetch screen capture data"
          placement={placement}
          closable={false}
          onClose={handleScreenShotOnClose}
          open={isScreenShotOpen}
          key={placement}
          height={"100%"}
          width={
            typeof window !== "undefined" && window.innerWidth > 768
              ? "50%"
              : "90%"
          }
        >
          <div className={"website-screenshot-drawer-main"}>
            <div className={"screenshot-drawer-input-btn"}>
              <div className={"website-screenshot-drawer-input-main"}>
                <ImageComponent
                  imageSrc={searchIcon}
                  imageAlt=""
                  imageClassName={"search-icon"}
                />
                <InputComponent
                  type={"url"}
                  error={websiteError?.ssUrl}
                  name={"ssUrl"}
                  handleChange={(e) => handleScreenShotDrawerChange(e)}
                  handleBlur={(e) => handleFocus(e, "ssUrl")}
                  value={screenShotData?.ssUrl}
                  className={"website-screenshot-drawer-input"}
                  placeholder="Enter site URL"
                />
              </div>
              <div>
                <ButtonComponent
                  btnText={
                    isGetScreenshotDetails ? <LoadingOutlined /> : "Get Details"
                  }
                  handleClick={handleGetScreenShotDetails}
                  classNames={"get-details"}
                  disabled={isGetScreenshotDetails && true}
                />
              </div>
            </div>
            {!isEmpty(getScreenShotData) &&
            Object?.values(getScreenShotData).every((val) => val) ? (
              <div className={"web-snapshot-main"}>
                <div className={"screenshot-image-section"}>
                  <h2 className={"screenshot-title"}>Screenshot Image</h2>
                  <div className={"website-ss-images-main"}>
                    <ImageComponent
                      imageSrc={`data:image/jpeg;base64,${getScreenShotData?.screenShotImg}`}
                      imageAlt="Image"
                      imageClassName={"website_ss_images"}
                      imageWidth={556}
                      imageHeight={500}
                    />
                  </div>
                </div>
                <div className={"nav-drawer-colors"}>
                  <h2 className={"color-title-main"}>Colors</h2>
                  <div className={"colors-filters-main"}>
                    <div className={"colors-filters"}>
                      {filterGetColor(getScreenShotData?.color?.join(","))
                        ?.slice(0, 7)
                        ?.map((obj) => {
                          return (
                            <React.Fragment key={obj}>
                              <div
                                style={{
                                  backgroundColor: obj,
                                }}
                                className={"colors"}
                              >
                                <p className={"color-code-text"}>HEX {obj}</p>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className={"font-main"}>
                  <h2 className={"font-title"}>Fonts</h2>
                  <div className={"font-family-main"}>
                    {getScreenShotData?.fontFamily?.map((obj) => (
                      <div key={obj}>
                        <p className={"font-family-text"}>{obj}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : isGetScreenshotDetails ? (
              <Lottie
                loop
                animationData={fontLoader}
                play
                className={"drawer-loading-image"}
              />
            ) : (
              <div className={"search-image"}>
                <Lottie
                  loop
                  animationData={searchWebsite}
                  play
                  className={"search-website"}
                />
              </div>
            )}
          </div>
          <div className={"website-screenshot-heading-main"}>
            <h1 className={"winning-website-heading"}>Top 10 websites</h1>
            <WebsiteCardCarouselView
              {...{
                token,
                slidesToShowImage: isScreenShotOpen ? 1 : 3,
                websiteCardData: topWebsiteData,
                isScreenShotOpen,
                handleOnMouseHover,
                isPortfolioWebsite,
                userOnHoverFilterData,
                drawer,
                setDrawer,
                modal,
                setModal,
              }}
            />
          </div>
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default WebSnapShotView;
